import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Button from '@mui/material/Button';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import IconButton from '@mui/material/IconButton';

import ServiceInfo from './ServiceInfo';
import CidrInfo from './CidrInfo';
import AddNewMachineCard from './AddNewMachineCard';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ApiConnection from '../../apiConnection/apiConnection';
import Alert from '@mui/material/Alert';

function MachineCard(props) {
  const [currentMachineOpen, setCurrentMachineOpen] = useState(props.open);
  const [currentCidrRules, setCurrentCidrRules] = useState([]);
  const [currentDirection, setCurrentDirection] = useState(null);
  const [currentCidrMachine, setCurrentCidrMachine] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const clusterManifest = props.clusterJson;

  const currentMachineConfig = get(
    clusterManifest,
    ['machines', currentMachineOpen],
    {}
  );

  const handleClickOpen = (machineName, machineInfo) => {
    setCurrentMachineOpen(machineName);
  };

  const handleClickOpenCidrRules = (direction, cidrRules, machineName) => {
    setCurrentDirection(direction);
    setCurrentCidrRules(cidrRules);
    setCurrentCidrMachine(machineName);
  };

  const handleClickClose = () => {
    setCurrentMachineOpen(null);
  };

  const handleClickCloseCidrRules = () => {
    setCurrentDirection(null);
    setCurrentCidrRules([]);
    setCurrentCidrMachine('');
  };

  const handleDeleteMachine = (machineName) => {
    if (window.confirm(`Are you sure you wish to delete "${machineName}"`)) {
      ApiConnection.deleteMachine(
        props.organizationName,
        props.authProvider.token,
        get(clusterManifest, 'name'),
        machineName
      )
        .then(() => {
          setErrorMessage('');
          props.resetClusterJsons();
        })
        .catch((err) => {
          setErrorMessage(`Failed to delete machine "${machineName}"`);
        });
    }
  };

  const machines = Object.keys(get(clusterManifest, 'machines', {}));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={4}>
          {machines.map((machineName) => {
            const machineInfo = get(
              clusterManifest,
              ['machines', machineName],
              {}
            );
            const externalIp = get(machineInfo, 'externalIp', '');
            const internalIp = get(machineInfo, 'internalIp', '');
            return (
              <Grid key={machineName} item>
                <Card style={{ width: 300, height: 300 }}>
                  {isEmpty(errorMessage) ? (
                    ''
                  ) : (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}
                  <CardHeader
                    title={machineName}
                    subheader={get(machineInfo, 'hostName', 'no host name')}
                  />
                  <CardContent>
                    <ul>
                      <li>
                        External Ip:{' '}
                        {isEmpty(externalIp)
                          ? 'No external IP provided'
                          : externalIp}
                      </li>
                      <li>
                        Internal Ip:{' '}
                        {isEmpty(internalIp)
                          ? 'No internal IP provided'
                          : internalIp}
                      </li>
                    </ul>
                  </CardContent>
                  <CardActions>
                    <IconButton
                      onClick={() => handleClickOpen(machineName, machineInfo)}
                      color="primary"
                    >
                      <SettingsApplicationsIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteMachine(machineName)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                    <Button
                      size="small"
                      onClick={() =>
                        handleClickOpenCidrRules(
                          'Inbound',
                          get(machineInfo, 'inboundTraffic', []),
                          machineName
                        )
                      }
                    >
                      InBound Rules
                    </Button>
                    <Button
                      size="small"
                      onClick={() =>
                        handleClickOpenCidrRules(
                          'Outbound',
                          get(machineInfo, 'outboundTraffic', []),
                          machineName
                        )
                      }
                    >
                      OutBound Rules
                    </Button>
                  </CardActions>
                </Card>
                <ServiceInfo
                  open={
                    !isEmpty(currentMachineOpen) &&
                    currentMachineOpen === machineName
                  }
                  onClose={handleClickClose}
                  currentMachineConfig={currentMachineConfig}
                  currentMachineKey={currentMachineOpen}
                  clusterName={get(clusterManifest, 'name')}
                  currentMachineName={currentMachineOpen}
                  organizationName={props.organizationName}
                  resetClusterJsons={props.resetClusterJsons}
                  authProvider={props.authProvider}
                />
                <CidrInfo
                  open={
                    currentDirection != null &&
                    currentCidrMachine === machineName
                  }
                  rules={currentCidrRules}
                  onClose={handleClickCloseCidrRules}
                  direction={currentDirection}
                  clusterName={get(clusterManifest, 'name')}
                  organizationName={props.organizationName}
                  machineId={machineName}
                  resetClusterJsons={props.resetClusterJsons}
                  authProvider={props.authProvider}
                />
              </Grid>
            );
          })}
          <AddNewMachineCard
            currentMachines={machines}
            clusterName={get(clusterManifest, 'name')}
            organizationName={props.organizationName}
            resetClusterJsons={props.resetClusterJsons}
            authProvider={props.authProvider}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MachineCard;
