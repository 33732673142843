import React, { useState, useEffect } from 'react';
import ApiConnection from '../../apiConnection/apiConnection';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import { isEmpty, isNull, get, has, map } from 'lodash';
//The different logos for hosting types
import azureLogo from './pics/azure.png';
import gcpLogo from './pics/gcp.png';
import awsLogo from './pics/aws.png';
import onPremLogo from './pics/onPrem.png';
//The different logos for IaC tools
import terraformLogo from './pics/terraform.png';
import cloudFormationLogo from './pics/cloudformation.png';
import azureResourceManagerLogo from './pics/arm.png';
import IconButton from '@mui/material/IconButton';
import AssessmentIcon from '@mui/icons-material/Assessment';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import AccordionActions from '@mui/material/AccordionActions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import AddCluster from './AddCluster';

import MachineCard from './MachineCard';
import ClusterServiceInfo from './ClusterServiceInfo';

/**
 The general cluster display is an material-ui accordion.
*/
function ClusterDisplay(props) {
  const [currentMachineOpen, setCurrentMachineOpen] = useState('');
  const [clusterJsons, setClusterJsons] = useState(null);
  const [currentExpand, setCurrentExpand] = useState('');
  const [reportInProgress, setReportInProgress] = useState(false);
  const [addCluster, setAddCluster] = useState(false);
  const [clusterServiceInfoOpen, setClusterServiceInfoOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const clusterTypeLogoMap = {
    AWS: awsLogo,
    GCP: gcpLogo,
    AZURE: azureLogo,
    ON_PREM: onPremLogo,
  };

  const iacToolsLogoMap = {
    TERRAFORM: terraformLogo,
    CLOUDFORMATION: cloudFormationLogo,
    AZURE_RESOURCE_MANAGER: azureResourceManagerLogo,
  };

  const resetClusterJsons = (currentMachineOpenChanger) => {
    setCurrentMachineOpen(currentMachineOpenChanger);
    setErrorMessage('');
    setClusterJsons(null);
  };

  const handleExpand = (clusterListItem, expanded) => {
    if (expanded) {
      setCurrentExpand(clusterListItem);
    } else {
      setCurrentExpand('');
    }
  };

  /**
   * The handler when someone is pressing the run assessment button
   */
  const handleRunAssessment = () => {
    setReportInProgress(true);
    const errorList = [];
    if (!isEmpty(clusterJsons)) {
      Promise.all(
        map(Object.keys(clusterJsons), (clusterName) =>
          ApiConnection.runReport(
            props.authProvider.tokenParsed.organization,
            props.authProvider.token,
            clusterName
          ).catch((err) => {
            errorList.push(clusterName);
          })
        )
      ).then(() => {
        if (!isEmpty(errorList)) {
          setErrorMessage(
            `Something went wrong running the report for ${errorList.join(
              ', '
            )}`
          );
        } else {
          setErrorMessage('');
        }
        setReportInProgress(false);
      });
    }
  };

  /**
   * The handler of the on-click method when someone wants to delete a cluster
   */
  const handleDeleteCluster = (clusterName) => {
    if (window.confirm(`Are you sure you wish to delete "${clusterName}"`)) {
      ApiConnection.deleteCluster(
        props.authProvider.tokenParsed.organization,
        props.authProvider.token,
        clusterName
      )
        .then(() => {
          resetClusterJsons('');
        })
        .catch((err) => {
          setErrorMessage(`Failed to delete cluster with name ${clusterName}`);
        });
    }
  };

  // We have to add that eslint exception, because it was somehow complaining
  // about the mounted variable, but there is also no way to avoid that one.
  /* eslint no-loop-func: "off" */
  useEffect(() => {
    let mounted = true;
    if (isNull(clusterJsons)) {
      ApiConnection.getClusterListPromise(
        props.authProvider.tokenParsed.organization,
        props.authProvider.token
      )
        .then((clusterListResult) => {
          const clusterJsonsKeys =
            clusterJsons == null ? [] : Object.keys(clusterJsons);
          if (
            !isEmpty(clusterListResult) &&
            !clusterListResult.every(
              (elem) => clusterJsonsKeys.indexOf(elem) !== -1
            )
          ) {
            const finalState = {};
            /*
              There is a need here to explain that finalState variable and what we are doing.
              We initialize each element that we get from the clusterlist call (which essentially
              returns just a list of identifiers), and set the identifier as a key for finalState
              and initialize it with null. Then we put the clusterJsons initialized as this
              finalState, and go with the next mount of this component into the else-clause of the
              underlying conditional. There, we fill in the actual data for each cluster. We
              initialize with null so that we can distinguish empty clusters from
              uninitialized ones.
             */
            clusterListResult.forEach(
              (clusterElem) => (finalState[clusterElem] = null)
            );
            setClusterJsons(finalState);
          }
        })
        .catch((err) => {
          setClusterJsons({});
        });
    } else {
      for (let clusterListItem in clusterJsons) {
        if (!mounted) {
          break;
        }
        const clusterJson = get(clusterJsons, clusterListItem);
        if (clusterJson == null) {
          ApiConnection.getClusterManifest(
            props.authProvider.tokenParsed.organization,
            props.authProvider.token,
            clusterListItem
          )
            .then((clusterJsonResponse) => {
              if (
                mounted &&
                isEmpty(clusterJson) &&
                !isEmpty(clusterJsonResponse)
              ) {
                setClusterJsons({
                  ...clusterJsons,
                  [clusterListItem]:
                    clusterJsonResponse == null ? {} : clusterJsonResponse,
                });
              }
            })
            .catch((err) => {
              setErrorMessage(
                `Could not get detailed information on ${clusterListItem}`
              );
            });
          break;
        }
      }
    }
    return () => (mounted = false);
  });

  if (!isEmpty(clusterJsons)) {
    return (
      <div>
        {isEmpty(errorMessage) ? (
          ''
        ) : (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {Object.keys(clusterJsons).map((clusterListItem) => {
          const clusterJson = get(clusterJsons, clusterListItem, {});
          let clusterTypeImage = '';
          if (
            has(clusterJson, 'clusterType') &&
            has(clusterTypeLogoMap, get(clusterJson, 'clusterType'))
          ) {
            const clusterImageKey = get(clusterJson, 'clusterType');
            clusterTypeImage = (
              <img
                src={get(clusterTypeLogoMap, clusterImageKey)}
                alt={`${clusterImageKey} logo`}
                style={{ width: '15px', height: '15px', marginLeft: '5px' }}
              />
            );
          }
          let iacToolImage = '';
          if (
            has(clusterJson, 'derivedFrom') &&
            has(iacToolsLogoMap, get(clusterJson, 'derivedFrom'))
          ) {
            const iacImageKey = get(clusterJson, 'derivedFrom');
            iacToolImage = (
              <img
                src={get(iacToolsLogoMap, iacImageKey)}
                alt={`${iacImageKey} logo`}
                style={{
                  width: '15px',
                  height: '15px',
                  marginLeft: '5px',
                  marginRight: '10px',
                }}
              />
            );
          }
          const location = get(clusterJson, 'location');
          return (
            <Accordion
              id={clusterListItem}
              key={clusterListItem}
              expanded={clusterListItem === currentExpand}
              onChange={(evt, exp) => handleExpand(clusterListItem, exp)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="machine-content"
                id="machine-header"
              >
                <Typography>
                  {clusterListItem}
                  {clusterTypeImage}
                  {iacToolImage}
                  {isEmpty(location) ? '' : `(${location})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {clusterListItem === currentExpand && (
                  <MachineCard
                    organizationName={
                      props.authProvider.tokenParsed.organization
                    }
                    clusterName={clusterListItem}
                    clusterJson={clusterJson}
                    open={currentMachineOpen}
                    resetClusterJsons={resetClusterJsons}
                    authProvider={props.authProvider}
                  />
                )}
              </AccordionDetails>
              <AccordionActions>
                <IconButton
                  color="primary"
                  aria-label="Edit Cluster Services"
                  onClick={() => setClusterServiceInfoOpen(true)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  aria-label="Delete Cluster"
                  onClick={() => handleDeleteCluster(clusterListItem)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </AccordionActions>
              {currentExpand === clusterListItem &&
                !isEmpty(get(clusterJsons, [clusterListItem])) && (
                  <ClusterServiceInfo
                    open={clusterServiceInfoOpen}
                    onClose={() => setClusterServiceInfoOpen(false)}
                    clusterServices={get(
                      clusterJsons,
                      [clusterListItem, 'clusterServices'],
                      {}
                    )}
                    clusterName={get(clusterJsons, [clusterListItem, 'name'])}
                    organizationName={
                      props.authProvider.tokenParsed.organization
                    }
                    resetClusterJsons={resetClusterJsons}
                    authProvider={props.authProvider}
                  />
                )}
            </Accordion>
          );
        })}
        <br />
        <br />
        <center>
          Add Cluster:{' '}
          <IconButton
            color="primary"
            aria-label="Add Cluster"
            onClick={() => setAddCluster(!addCluster)}
          >
            <AddIcon />
          </IconButton>
          <br />
          Run Reports:
          {!reportInProgress ? (
            <IconButton
              color="primary"
              aria-label="Run Assessment"
              onClick={() => handleRunAssessment()}
            >
              <AssessmentIcon />
            </IconButton>
          ) : (
            <QueryBuilderIcon />
          )}
        </center>
        <AddCluster
          organizationName={props.authProvider.tokenParsed.organization}
          open={addCluster}
          onClose={() => setAddCluster(false)}
          resetClusterJsons={resetClusterJsons}
          existingClusterNames={Object.keys(clusterJsons)}
          authProvider={props.authProvider}
        />
      </div>
    );
  } else {
    return (
      <div>
        {isEmpty(errorMessage) ? (
          ''
        ) : (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        There are currently no clusters registered.
        <center>
          Add Cluster:{' '}
          <IconButton
            color="primary"
            onClick={() => setAddCluster(!addCluster)}
          >
            <AddIcon />
          </IconButton>
        </center>
        <AddCluster
          organizationName={props.authProvider.tokenParsed.organization}
          open={addCluster}
          onClose={() => setAddCluster(false)}
          resetClusterJsons={resetClusterJsons}
          existingClusterNames={
            clusterJsons == null ? [] : Object.keys(clusterJsons)
          }
          authProvider={props.authProvider}
        />
      </div>
    );
  }
}

export default ClusterDisplay;
