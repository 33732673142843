import { useEffect, useState } from 'react';
import { isEmpty, map, max, sum } from 'lodash';
import { useToken } from './useToken.hook';
import ApiConnection from '../../apiConnection/apiConnection';
import { DateTime } from 'luxon';

const getLastRanAsString = (clusterMap) => {
  if (isEmpty(clusterMap)) {
    return 'Never';
  }
  const allLastRanValues = map(clusterMap, (acc) => acc['timestamp']);
  const lastRan = max(allLastRanValues);
  const today = DateTime.now();
  const datetime = DateTime.fromMillis(Number.parseInt(lastRan * 1000, 10));
  const diff = today.diff(datetime, ['days']);
  const daysDiff = diff.values.days;
  if (daysDiff) {
    if (daysDiff === -1) {
      return 'Never';
    } else {
      return `${Math.floor(daysDiff).toFixed()} days ago`;
    }
  }
  return 'Today';
};

const clusterMapToSeverityCounts = (clusterMap) => {
  const clusterNames = Object.keys(clusterMap);
  const accumulator = [0, 0, 0, 0, 0];
  const seriesValues = clusterNames.map((name) => {
    return clusterMap[name]['report'];
  });
  seriesValues
    .filter((totalsMap) => !isEmpty(totalsMap))
    .forEach((totalsMap) => {
      accumulator[0] = accumulator[0] + totalsMap.sev1;
      accumulator[1] = accumulator[1] + totalsMap.sev2;
      accumulator[2] = accumulator[2] + totalsMap.sev3;
      accumulator[3] = accumulator[3] + totalsMap.sev4;
      accumulator[4] = accumulator[4] + totalsMap.sev5;
    });
  return accumulator;
};

const clusterMapToTotalReportCount = (clusterMap) => {
  const clusterNames = Object.keys(clusterMap);
  return sum(clusterNames.map((name) => clusterMap[name]['totalReports']));
};

const fetchOrgSummary = (organizationName, token) => {
  return ApiConnection.getOrgSummary(organizationName, token);
};

export const useOrganizationSummary = (organizationName) => {
  const [loading, setLoading] = useState(true);
  const [clusterMap, setClusterMap] = useState(null);
  const [error, setError] = useState(null);
  const token = useToken();
  useEffect(() => {
    if (!organizationName || !token) return;
    fetchOrgSummary(organizationName, token)
      .then((clusterMap) => setClusterMap(clusterMap))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [organizationName, token]);

  if (!clusterMap) return null;

  return {
    severityCounts: clusterMapToSeverityCounts(clusterMap),
    clusterMap,
    clusterCount: Object.keys(clusterMap).length,
    totalReports: clusterMapToTotalReportCount(clusterMap),
    lastRan: getLastRanAsString(clusterMap),
    loading,
    error,
  };
};
