import React, { useState } from 'react';

import { supportedServices } from '../utils/supportedServicesMap.js';
import AddStandardConfigFileRow from './AddStandardConfigFileRow';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { get, has, isEmpty, set, remove, indexOf } from 'lodash';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddServiceCard from './AddNewServiceCard';
import PublishIcon from '@mui/icons-material/Publish';
import ApiConnection from '../../apiConnection/apiConnection';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExposureIcon from '@mui/icons-material/Exposure';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import AddComplimentaryFileRow from './AddComplimentaryFileRow';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

let currentlySelectedConfigFile = {};
let currentlySelectedComplimentaryFile = {};

/**
 * The cluster service information component. This is very similar to the ServiceInfo
 * component, but some of the calls and displays are different.
 */
function ClusterServiceInfo(props) {
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => {
    props.onClose();
  };

  const handleConfigUpload = (evt) => {
    ApiConnection.uploadConfigFileClusterService(
      currentlySelectedConfigFile.organizationName,
      props.authProvider.token,
      currentlySelectedConfigFile.clusterName,
      currentlySelectedConfigFile.serviceKey,
      currentlySelectedConfigFile.fileName,
      currentlySelectedConfigFile.defaultFileName,
      currentlySelectedConfigFile.subPath,
      evt.target.files[0]
    )
      .then(() => {
        setErrorMessage('');
        currentlySelectedConfigFile = {};
      })
      .catch((err) => {
        setErrorMessage(
          `Failed to upload the configuration file named ${currentlySelectedConfigFile.fileName}.`
        );
      });
  };

  const handleComplimentaryUpload = (evt) => {
    ApiConnection.uploadComplimentaryFileClusterService(
      currentlySelectedComplimentaryFile.organizationName,
      props.authProvider.token,
      currentlySelectedComplimentaryFile.clusterName,
      currentlySelectedComplimentaryFile.serviceKey,
      currentlySelectedComplimentaryFile.fileName,
      currentlySelectedComplimentaryFile.subPath,
      evt.target.files[0],
      currentlySelectedComplimentaryFile.configFileType
    )
      .then(() => {
        setErrorMessage('');
        currentlySelectedComplimentaryFile = {};
      })
      .catch((err) => {
        setErrorMessage(
          `Failed to upload the complimentary file named ${currentlySelectedComplimentaryFile.fileName}.`
        );
      });
  };

  const removeConfigFile = (serviceKey, configFile) => {
    if (
      window.confirm(
        `Are you sure you wish to delete "${get(configFile, 'fileName')}"`
      )
    ) {
      ApiConnection.deleteStandardConfigFileClusterService(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        serviceKey,
        get(configFile, 'fileName'),
        get(configFile, 'defaultFileName'),
        get(configFile, 'subPath')
      )
        .then(() => {
          setErrorMessage('');
          props.resetClusterJsons('');
        })
        .catch((err) => {
          setErrorMessage(
            `Could not remove the configfile ${get(
              configFile,
              'fileName'
            )} from ${serviceKey}`
          );
        });
    }
  };

  const removeComplimentaryFile = (serviceKey, configFile) => {
    if (
      window.confirm(
        `Are you sure you wish to delete "${get(configFile, 'fileName')}"`
      )
    ) {
      ApiConnection.deleteComplimentaryFileClusterService(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        serviceKey,
        get(configFile, 'fileName'),
        get(configFile, 'subPath')
      )
        .then(() => {
          setErrorMessage('');
          props.resetClusterJsons(props.currentMachineKey);
        })
        .catch((err) => {
          setErrorMessage(
            `Could not remove the configfile ${get(
              configFile,
              'fileName'
            )} from ${serviceKey}`
          );
        });
    }
  };

  const handleDeleteService = (serviceKey) => {
    if (window.confirm(`Are you sure you wish to delete "${serviceKey}"`)) {
      ApiConnection.deleteClusterService(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        serviceKey
      )
        .then(() => {
          setErrorMessage('');
          props.resetClusterJsons('');
        })
        .catch((err) => {
          setErrorMessage(`Failed to delete service "${serviceKey}"`);
        });
    }
  };

  const runDeriveCluster = () => {
    if (
      window.confirm(
        'Are you sure you wish to derive machines from the IaC tool?'
      )
    ) {
      ApiConnection.runDeriveCluster(
        props.organizationName,
        props.authProvider.token,
        props.clusterName
      )
        .then(() => {
          setErrorMessage('');
          props.resetClusterJsons('');
        })
        .catch((err) => {
          setErrorMessage('Failed to derive cluster information');
        });
    }
  };

  const addToAliasList = (
    serviceKey,
    complimentaryFile,
    evt,
    value,
    reason
  ) => {
    if (!has(complimentaryFile, 'aliasList')) {
      set(complimentaryFile, 'aliasList', []);
    }
    const aliasList = get(complimentaryFile, 'aliasList');
    if (reason === 'createOption') {
      aliasList.push(evt.target.value);
    } else if (reason === 'removeOption') {
      remove(aliasList, (entry) => indexOf(value, entry) === -1);
    }
    ApiConnection.upsertComplimentaryFileEntryClusterService(
      props.organizationName,
      props.authProvider.token,
      props.clusterName,
      serviceKey,
      get(complimentaryFile, 'fileName'),
      get(complimentaryFile, 'subPath'),
      aliasList,
      get(complimentaryFile, 'tags'),
      get(complimentaryFile, 'configFileType', 'custom')
    )
      .then(() => {
        setErrorMessage('');
        props.resetClusterJsons(props.currentMachineKey);
      })
      .catch((err) => {
        setErrorMessage(
          'Failed to add alias to complimentary file ' +
            `"${get(complimentaryFile, 'fileName')}"`
        );
      });
  };

  /**
   * This function handles the download of the configuration file.
   */
  const handleDownloadFile = (isConfig, serviceKey, fileName, subPath) => {
    const funToUse = isConfig
      ? ApiConnection.downloadConfigFileClusterService
      : ApiConnection.downloadComplimentaryFileClusterService;
    funToUse(
      props.organizationName,
      props.authProvider.token,
      props.clusterName,
      serviceKey,
      fileName,
      subPath
    )
      .then((blob) => {
        const fileBlob = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = fileBlob;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        setErrorMessage(`Failed to download configuration file "${fileName}"`);
      });
  };

  const services = Object.keys(props.clusterServices);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      fullWidth
      maxWidth="xl"
    >
      <input
        id="file-input"
        type="file"
        name="name"
        style={{ display: 'none' }}
        onChange={(evt) =>
          isEmpty(currentlySelectedConfigFile)
            ? handleComplimentaryUpload(evt)
            : handleConfigUpload(evt)
        }
      />
      <DialogTitle>
        {props.clusterName}{' '}
        <IconButton
          color="primary"
          aria-label="Derive Machines In Cluster"
          onClick={runDeriveCluster}
        >
          <BuildCircleIcon />
        </IconButton>
      </DialogTitle>
      <Grid container justifyContent="center" spacing={4}>
        {isEmpty(errorMessage) ? (
          ''
        ) : (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {services.map((serviceKey) => {
          const serviceInfo = get(props.clusterServices, [serviceKey], {});
          const configurationFiles = get(serviceInfo, 'configFileList', []);
          const complimentaryFileList = get(
            serviceInfo,
            'complimentaryFileList',
            []
          );
          const version = get(serviceInfo, 'version', 'Unknown');
          const serviceName = get(serviceInfo, 'serviceName');
          const serviceDisplayName = get(supportedServices, [
            serviceName,
            'displayName',
          ]);
          return (
            <Grid key={serviceKey} item>
              <Card
                style={{
                  minWidth: 1000,
                  minHeight: 300,
                  overflowY: 'scroll',
                  overflowX: 'scroll',
                }}
              >
                <CardHeader
                  title={serviceKey}
                  subheader={`${serviceDisplayName} (Version: ${version})`}
                />
                <CardContent>
                  <Divider>STANDARD FILES</Divider>
                  <Table aria-label="Standard files">
                    <TableHead>
                      <TableRow>
                        <TableCell>File name</TableCell>
                        <TableCell>Default file name</TableCell>
                        <TableCell>Store prefix</TableCell>
                        <TableCell>Download/Upload</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          <ExposureIcon />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {configurationFiles.map((configFile) => {
                        return (
                          <TableRow
                            key={
                              get(configFile, 'fileName') +
                              get(configFile, 'subPath')
                            }
                          >
                            <TableCell>
                              {`${get(configFile, 'fileName')}`}
                            </TableCell>
                            <TableCell>
                              {`${get(configFile, 'defaultFileName')}`}
                            </TableCell>
                            <TableCell>
                              {`${get(configFile, 'subPath')}`}
                            </TableCell>
                            <TableCell>
                              <GetAppIcon
                                style={{
                                  fontSize: '14px',
                                  marginLeft: '5px',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  handleDownloadFile(
                                    true,
                                    serviceKey,
                                    get(configFile, 'fileName'),
                                    get(configFile, 'subPath')
                                  )
                                }
                              />
                              <PublishIcon
                                style={{
                                  fontSize: '14px',
                                  marginLeft: '5px',
                                  cursor: 'pointer',
                                }}
                                title="Update Config File"
                                onClick={() => {
                                  currentlySelectedConfigFile = {
                                    organizationName: props.organizationName,
                                    clusterName: props.clusterName,
                                    serviceKey,
                                    fileName: get(configFile, 'fileName'),
                                    defaultFileName: get(
                                      configFile,
                                      'defaultFileName'
                                    ),
                                    subPath: get(configFile, 'subPath'),
                                  };
                                  document.getElementById('file-input').click();
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="secondary"
                                aria-label="Remove Configuration File"
                                onClick={() =>
                                  removeConfigFile(serviceKey, configFile)
                                }
                              >
                                <IndeterminateCheckBoxIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <AddStandardConfigFileRow
                        clusterName={props.clusterName}
                        organizationName={props.organizationName}
                        serviceId={serviceKey}
                        serviceName={serviceName}
                        currentConfigFiles={configurationFiles}
                        resetClusterJsons={props.resetClusterJsons}
                        setErrorMessage={setErrorMessage}
                        authProvider={props.authProvider}
                      />
                    </TableBody>
                  </Table>
                  <div style={{ marginTop: '20px' }}>
                    <Divider>COMPLIMENTARY FILES</Divider>
                    <Table aria-label="Complimentary Files">
                      <TableHead>
                        <TableRow>
                          <TableCell>File name</TableCell>
                          <TableCell>Aliases</TableCell>
                          <TableCell>Store prefix</TableCell>
                          <TableCell>File Type</TableCell>
                          <TableCell>Download/Upload</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <ExposureIcon />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {complimentaryFileList.map((complimentaryFile) => {
                          return (
                            <TableRow
                              key={
                                get(complimentaryFile, 'fileName') +
                                get(complimentaryFile, 'subPath')
                              }
                            >
                              <TableCell>
                                {`${get(complimentaryFile, 'fileName')}`}
                              </TableCell>
                              <TableCell>
                                <Autocomplete
                                  multiple
                                  id="tags-filled"
                                  freeSolo
                                  options={[]}
                                  value={get(
                                    complimentaryFile,
                                    'aliasList',
                                    []
                                  )}
                                  onChange={(evt, value, reason) =>
                                    addToAliasList(
                                      serviceKey,
                                      complimentaryFile,
                                      evt,
                                      value,
                                      reason
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      inputProps={{
                                        'data-testid': 'Alias Fields Existing',
                                        ...params,
                                      }}
                                      variant="filled"
                                      placeholder="Add Aliases Cluster"
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                {`${get(complimentaryFile, 'subPath')}`}
                              </TableCell>
                              <TableCell>
                                {`${get(
                                  complimentaryFile,
                                  'configFileType',
                                  'custom'
                                )}`}
                              </TableCell>
                              <TableCell>
                                <GetAppIcon
                                  style={{
                                    fontSize: '14px',
                                    marginLeft: '5px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    handleDownloadFile(
                                      false,
                                      serviceKey,
                                      get(complimentaryFile, 'fileName'),
                                      get(complimentaryFile, 'subPath')
                                    )
                                  }
                                />
                                <PublishIcon
                                  style={{
                                    fontSize: '14px',
                                    marginLeft: '5px',
                                    cursor: 'pointer',
                                  }}
                                  title="Update Complimentary File"
                                  onClick={() => {
                                    currentlySelectedComplimentaryFile = {
                                      organizationName: props.organizationName,
                                      clusterName: props.clusterName,
                                      machineId: props.currentMachineKey,
                                      serviceKey,
                                      fileName: get(
                                        complimentaryFile,
                                        'fileName'
                                      ),
                                      subPath: get(
                                        complimentaryFile,
                                        'subPath'
                                      ),
                                      configFileType: get(
                                        complimentaryFile,
                                        'configFileType'
                                      ),
                                    };
                                    document
                                      .getElementById('file-input')
                                      .click();
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  color="secondary"
                                  aria-label="Remove Complimentary File"
                                  onClick={() =>
                                    removeComplimentaryFile(
                                      serviceKey,
                                      complimentaryFile
                                    )
                                  }
                                >
                                  <IndeterminateCheckBoxIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <AddComplimentaryFileRow
                          clusterName={props.clusterName}
                          organizationName={props.organizationName}
                          serviceId={serviceKey}
                          serviceName={serviceName}
                          currentComplimentaryFiles={complimentaryFileList}
                          resetClusterJsons={props.resetClusterJsons}
                          setErrorMessage={setErrorMessage}
                          authProvider={props.authProvider}
                        />
                      </TableBody>
                    </Table>
                  </div>
                </CardContent>
                <CardActions>
                  <IconButton
                    color="secondary"
                    aria-label="Remove Service"
                    onClick={() => handleDeleteService(serviceKey)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
        {services.length > 0 ? (
          ''
        ) : (
          <AddServiceCard
            serviceKeys={services}
            clusterName={props.clusterName}
            organizationName={props.organizationName}
            resetClusterJsons={props.resetClusterJsons}
            authProvider={props.authProvider}
          />
        )}
      </Grid>
    </Dialog>
  );
}

export default ClusterServiceInfo;
