import React, { useState } from 'react';

import { indexOf, isEmpty } from 'lodash';
import { isIP } from 'validator';
import ApiConnection from '../../apiConnection/apiConnection';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

function AddMachineCard(props) {
  const [newMachineAddSelected, setNewMachineAddSelected] = useState(false);
  const [newMachineName, setNewMachineName] = useState('');
  const [newMachineHostName, setNewMachineHostName] = useState('');
  const [externalIp, setExternalIp] = useState('');
  const [internalIp, setInternalIp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const verifyAllInfoGiven = () => {
    let result = true;
    result =
      result &&
      !isEmpty(newMachineName) &&
      indexOf(props.currentMachines, newMachineName) === -1;
    result = result && (isEmpty(externalIp) || isIP(externalIp));
    result = result && (isEmpty(internalIp) || isIP(internalIp));
    return result;
  };

  const handleMachineAdd = () => {
    const body = {
      id: newMachineName,
      hostName: newMachineHostName,
      externalIp,
      internalIp,
    };
    ApiConnection.addMachine(
      props.organizationName,
      props.authProvider.token,
      props.clusterName,
      body
    )
      .then(() => {
        setNewMachineAddSelected(false);
        setErrorMessage('');
        props.resetClusterJsons();
      })
      .catch((err) => {
        setErrorMessage(`Failed to add the new machine "${newMachineName}".`);
      });
  };

  if (!newMachineAddSelected) {
    return (
      <Grid item>
        <Card style={{ width: 300, height: 300 }}>
          <CardHeader title="Add new machine" />
          <CardContent
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => setNewMachineAddSelected(true)}
            >
              <AddIcon />
            </Fab>
          </CardContent>
          <CardActions />
        </Card>
      </Grid>
    );
  }
  return (
    <Grid item>
      <Card style={{ width: 300, height: 300, overflowY: 'scroll' }}>
        <CardHeader title="Add new machine" />
        <CardContent>
          <TextField
            label="Machine Name"
            value={newMachineName}
            onChange={(evt) => setNewMachineName(evt.target.value)}
            inputProps={{ 'aria-label': 'Machine Name Text Field' }}
          />
          <TextField
            label="Host name"
            value={newMachineHostName}
            onChange={(evt) => setNewMachineHostName(evt.target.value)}
            inputProps={{ 'aria-label': 'Host Name Text Field' }}
          />
          <TextField
            label="External IP"
            value={externalIp}
            onChange={(evt) => setExternalIp(evt.target.value)}
            inputProps={{ 'aria-label': 'External Ip Text Field' }}
          />
          <TextField
            label="Internal IP"
            value={internalIp}
            onChange={(evt) => setInternalIp(evt.target.value)}
            inputProps={{ 'aria-label': 'Internal Ip Text Field' }}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!verifyAllInfoGiven()}
            onClick={handleMachineAdd}
            aria-label="Add Machine Button"
          >
            Add Machine
          </Button>
          <Button
            variant="contained"
            color="secondary"
            aria-label="Cancel Add Machine Button"
            onClick={() => setNewMachineAddSelected(false)}
          >
            Cancel
          </Button>
          {isEmpty(errorMessage) ? (
            ''
          ) : (
            <Alert severity="error">{errorMessage}</Alert>
          )}
        </CardContent>
        <CardActions />
      </Card>
    </Grid>
  );
}

export default AddMachineCard;
