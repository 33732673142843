import React, { useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  LinearProgress,
} from '@mui/material';
import { getBadPalette, getSemanticPalette } from '../colors';
import { getLabelForSeverity } from './util/SeverityLabelsUtil';
import { useParams } from 'react-router';
import { sumIssuesBySeverityLevel } from './util';
import { ClusterReportTable } from './components/ClusterReportTable';
import { getDaysAgo } from '../utils/getDaysAgo';
import { getDateTime } from '../utils/getDatetime';
import { useReportForCluster } from '../hooks/useReportForCluster.hook';

const ViewReportDropdown = ({ reportId, reportIds, onChange }) => {
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={reportId}
      onChange={onChange}
    >
      {reportIds.map((timestampSec, i) => (
        <MenuItem value={timestampSec} key={i}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={'space-between'}
            style={{ width: '100%' }}
          >
            {getDateTime(timestampSec)}
          </Stack>
        </MenuItem>
      ))}
    </Select>
  );
};

const SeverityCard = ({ label, count, color }) => (
  <Box sx={{ flex: 1, marginX: 1 }}>
    <Box
      sx={{
        border: `1px solid ${getSemanticPalette('light').neutral}`,
        borderRadius: 3,
      }}
    >
      <Box sx={{ paddingLeft: 2 }}>
        <Box sx={{ fontSize: 12 }}>{label}</Box>
        <Box sx={{ fontSize: 20, color }}>{count}</Box>
      </Box>
    </Box>
  </Box>
);

export const ReportPanel = ({ clusterReports }) => {
  const { clusterName, organizationName } = useParams();

  const reportIds = clusterReports
    .sort()
    .reverse()
    .map((id) => Number(id));
  const totalReports = reportIds.length;
  const [reportId, setReportId] = useState(reportIds[0]);

  const handleReportChange = (event) => {
    setReportId(event.target.value);
  };

  const lastReportId = reportIds[0];

  const badPalette = getBadPalette('badPalette1');

  const { report: reportUse } = useReportForCluster(
    organizationName,
    clusterName,
    reportId
  );

  const report = useMemo(() => (reportUse ? reportUse : {}), [reportUse]);

  const [
    severity1Count,
    severity2Count,
    severity3Count,
    severity4Count,
    severity5Count,
  ] = sumIssuesBySeverityLevel(report);

  return isEmpty(reportIds) ? (
    <Box sx={{ lineHeight: 3 }}>
      <LinearProgress />
    </Box>
  ) : (
    <Box sx={{ lineHeight: 3 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>Total Reports: {totalReports}</Box>
        {!isEmpty(report) && (
          <FormControl variant="standard">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box style={{ marginRight: 12 }}>View Report:</Box>

              <ViewReportDropdown
                reportId={reportId}
                reportIds={reportIds}
                onChange={handleReportChange}
              />
            </Box>
          </FormControl>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', fontSize: 12 }}>
        Last Scan: {getDaysAgo(lastReportId)} days ago -{' '}
        {getDateTime(lastReportId)}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <SeverityCard
          label={getLabelForSeverity(5)}
          count={severity5Count}
          color={badPalette.bad5}
        />
        <SeverityCard
          label={getLabelForSeverity(4)}
          count={severity4Count}
          color={badPalette.bad4}
        />
        <SeverityCard
          label={getLabelForSeverity(3)}
          count={severity3Count}
          color={badPalette.bad3}
        />
        <SeverityCard
          label={getLabelForSeverity(2)}
          count={severity2Count}
          color={badPalette.bad2}
        />
        <SeverityCard
          label={getLabelForSeverity(1)}
          count={severity1Count}
          color={badPalette.bad1}
        />
      </Box>

      <ClusterReportTable report={report} />
    </Box>
  );
};
