import { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import ApiConnection from '../../apiConnection/apiConnection';
import { useAuthProvider } from '../../AuthProvider';

/**
 *
 * @param {string} organizationName
 * @param {string} token
 * @param {string} clusterName
 * @param {string} reportName
 * @returns {Promise<*>}
 */
const getClusterReportJson = (
  organizationName,
  token,
  clusterName,
  reportName,
  isDefaultAccount
) => {
  if (!isDefaultAccount) {
    return ApiConnection.getClusterReportJson(
      organizationName,
      token,
      clusterName,
      reportName
    );
  }
  return ApiConnection.getCliClusterReportJson(
    organizationName,
    token,
    clusterName,
    reportName
  );
};

/**
 *
 * @param {string} organizationName
 * @param {string} token
 * @param {string} clusterName
 * @param {number} reportName
 * @returns {{loading: boolean, error: unknown, clusters: unknown}}
 */
export const useReportForCluster = (
  organizationName,
  clusterName,
  reportName
) => {
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);
  const authProvider = useAuthProvider();
  const token = authProvider.token;
  const isDefaultAccount = isEmpty(
    get(authProvider, ['tokenParsed', 'organization'], [])
  );
  useEffect(() => {
    if (!organizationName || !token) {
      return;
    }
    if (!clusterName) {
      return;
    }
    if (!reportName) {
      return;
    }
    setLoading(true);
    getClusterReportJson(
      organizationName,
      token,
      clusterName,
      reportName,
      isDefaultAccount
    )
      .then((reports) => setReport(reports))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [organizationName, token, clusterName, reportName, isDefaultAccount]);

  return {
    loading,
    report,
    error,
  };
};
