import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { concat, findIndex, get, has, isEmpty } from 'lodash';
import ApiConnection from '../../apiConnection/apiConnection';

import { supportedServices } from '../utils/supportedServicesMap.js';

function AddStandardConfigFileRow(props) {
  const [fileName, setFileName] = useState('');
  const [subPath, setSubPath] = useState('.');
  const [defaultFileName, setDefaultFileName] = useState('');
  const [configFile, setConfigFile] = useState(null);
  const defaultConfigFiles = get(supportedServices, [
    props.serviceName,
    'mandatoryFiles',
  ]);

  /**
   * A testing function to figure out if a new config file can be added or not.
   * It checks that the important fields are there, and if the file is not representing
   * something already existing.
   */
  const newConfigFileCanBeAdded = () => {
    return (
      !isEmpty(fileName) &&
      !isEmpty(subPath) &&
      !isEmpty(defaultFileName) &&
      !(configFile == null) &&
      findIndex(
        concat(props.currentConfigFiles, props.currentComplimentaryFiles),
        (conf) =>
          get(conf, 'fileName') === fileName &&
          get(conf, 'subPath').replace(/\/+$/, '') ===
            subPath.replace(/\/+$/, '')
      ) === -1
    );
  };

  /**
   * The call to the endpoints once the button is being pressed to add
   * the file.
   */
  const handleCreateNewConfigFileEntry = () => {
    if (has(props, 'machineId')) {
      ApiConnection.upsertConfigFileEntry(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        props.machineId,
        props.serviceId,
        fileName,
        defaultFileName,
        subPath,
        [],
        []
      )
        .then((upsertResult) => {
          if (upsertResult !== 'false') {
            ApiConnection.uploadConfigFile(
              props.organizationName,
              props.authProvider.token,
              props.clusterName,
              props.machineId,
              props.serviceId,
              fileName,
              defaultFileName,
              subPath,
              configFile
            )
              .then(() => {
                setFileName('');
                setSubPath('.');
                setDefaultFileName('');
                props.setErrorMessage('');
                props.resetClusterJsons(props.machineId);
              })
              .catch((err) => {
                props.setErrorMessage(
                  `Failed to upload the configuration file named ${fileName}.`
                );
              });
          }
        })
        .catch((err) => {
          props.setErrorMessage(
            `Failed to upload the configuration file named ${fileName}.`
          );
        });
    } else {
      ApiConnection.upsertConfigFileEntryClusterService(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        props.serviceId,
        fileName,
        defaultFileName,
        subPath,
        [],
        []
      )
        .then((upsertResult) => {
          if (upsertResult !== 'false') {
            ApiConnection.uploadConfigFileClusterService(
              props.organizationName,
              props.authProvider.token,
              props.clusterName,
              props.serviceId,
              fileName,
              defaultFileName,
              subPath,
              configFile
            )
              .then(() => {
                setFileName('');
                setSubPath('.');
                setDefaultFileName('');
                props.setErrorMessage('');
                props.resetClusterJsons('');
              })
              .catch((err) => {
                props.setErrorMessage(
                  `Failed to upload the configuration file named ${fileName}.`
                );
              });
          }
        })
        .catch((err) => {
          props.setErrorMessage(
            `Failed to upload the configuration file entry named ${fileName}.`
          );
        });
    }
  };

  return (
    <TableRow>
      <TableCell>
        <TextField
          value={fileName}
          onChange={(evt) => setFileName(evt.target.value)}
          style={{ width: '20ch' }}
          inputProps={{ 'data-testid': 'File Name Config File' }}
        />
      </TableCell>
      <TableCell>
        <FormControl>
          <Select
            value={defaultFileName}
            onChange={(evt) => setDefaultFileName(evt.target.value)}
            style={{ width: '25ch' }}
            inputProps={{ 'data-testid': 'Default File Name Config File' }}
          >
            {defaultConfigFiles.map((configFile) => (
              <MenuItem key={configFile} value={configFile}>
                {configFile}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <TextField
          value={subPath}
          onChange={(evt) => setSubPath(evt.target.value)}
          style={{ width: '10ch' }}
          inputProps={{ 'data-testid': 'SubPath Config File' }}
        />
      </TableCell>
      <TableCell>
        <input
          type="file"
          name="ConfigFile"
          data-testid="Config File Upload Field"
          onChange={(evt) => setConfigFile(evt.target.files[0])}
        />
      </TableCell>
      <TableCell>
        <IconButton
          color="primary"
          disabled={!newConfigFileCanBeAdded()}
          aria-label="Add New Config File Button"
          onClick={handleCreateNewConfigFileEntry}
        >
          <AddBoxIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default AddStandardConfigFileRow;
