import React, { useMemo } from 'react';
import { StackedBarChart } from '../../charts/StackedBarChart';
import { getBadPalette } from '../../colors';
import { getLabelForSeverity } from '../util/SeverityLabelsUtil';
import { chartHasData } from '../util/ChartsUtil';
import { get } from 'lodash';
import { Box } from '@mui/material';

const CLUSTERS_TO_DISPLAY_IN_CHART = 8;

const getTotalForSeries = (serviesValues) => {
  const totalIssuesArray = serviesValues.slice();
  // Removing first element of the series since that's the title
  totalIssuesArray.shift();
  return totalIssuesArray.reduce((prev, cur) => prev + cur, 0);
};

const compareSeries = (seriesA, seriesB) => {
  const totalSeriesA = getTotalForSeries(seriesA);
  const totalSeriesB = getTotalForSeries(seriesB);
  return totalSeriesB - totalSeriesA;
};

const clusterMapToStackedBarChartData = (clusterMap) => {
  const clusterNames = Object.keys(clusterMap);
  const seriesTitles = [
    'Cluster',
    getLabelForSeverity(1),
    getLabelForSeverity(2),
    getLabelForSeverity(3),
    getLabelForSeverity(4),
    getLabelForSeverity(5),
  ];
  const seriesValues = clusterNames.map((name) => {
    return [
      name,
      get(clusterMap, [name, 'report', 'sev1'], 0),
      get(clusterMap, [name, 'report', 'sev2'], 0),
      get(clusterMap, [name, 'report', 'sev3'], 0),
      get(clusterMap, [name, 'report', 'sev4'], 0),
      get(clusterMap, [name, 'report', 'sev5'], 0),
    ];
  });
  seriesValues.sort(compareSeries);

  return [seriesTitles, ...seriesValues.slice(0, CLUSTERS_TO_DISPLAY_IN_CHART)];
};

const getColorPalette = (paletteMap) => {
  return [
    paletteMap['bad1'],
    paletteMap['bad2'],
    paletteMap['bad3'],
    paletteMap['bad4'],
    paletteMap['bad5'],
  ];
};

export const FailedChecksByCluster = ({ clusterMap }) => {
  const xAxisLabel = 'Failed checks by cluster';
  const yAxisLabel = 'Clusters';
  const paletteMap = getBadPalette('badPalette1');
  const colorPalette = getColorPalette(paletteMap);
  const data = useMemo(
    () => clusterMapToStackedBarChartData(clusterMap),
    [clusterMap]
  );
  const _chartHasData = useMemo(() => chartHasData(data), [data]);
  if (!_chartHasData) {
    return (
      <Box style={{ marginTop: '200px' }}>
        <div>No Issues Found.</div>
      </Box>
    );
  }

  return (
    <StackedBarChart
      data={data}
      colorPalette={colorPalette}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisLabel}
    />
  );
};
