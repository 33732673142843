import React, { useState, useEffect } from 'react';
import './App.css';
import config from './config/configuration';
import Keycloak from 'keycloak-js';
import { BrowserRouter } from 'react-router-dom';
import { CoGuardEnterprise } from './coguardViews/CoGuardEnterprise';
import Menu from './Menu/Menu';
import { LoginPage } from './LoginPage';
import ReactGA from 'react-ga4';
import { get, indexOf } from 'lodash';
import Alert from '@mui/material/Alert';
import { AuthProvider } from './AuthProvider';
import { ThemeProvider } from '@emotion/react';
import { theme } from './Theme';

ReactGA.initialize('G-2KXKKDPYKB', {
  testMode: process.env.NODE_ENV === 'test',
});

const SUBSCRIPTION_ROLE_ENUM = {
  ENTERPRISE: 'ENTERPRISE',
  DEV: 'DEV',
};

const VIEWS_ENUM = {
  COGUARD_ENTERPRISE: 'coguard-enterprise',
  AUTH_LOADING_SCREEN: 'auth-loading-screen',
  FREE_OR_DEV: 'free-or-dev',
  ERROR_VIEW: 'auth-error',
};

const App = () => {
  const [keycloak] = useState(new Keycloak(config.data.keycloakConf));
  const [currentView, setCurrentView] = useState(
    VIEWS_ENUM.AUTH_LOADING_SCREEN
  );

  const changeGlobalView = (name) => {
    setCurrentView(name);
  };

  const updateToken = () => {
    if (keycloak) {
      keycloak.updateToken(300).catch(() => {
        console.error('Could not update token');
        setCurrentView(VIEWS_ENUM.ERROR_VIEW);
      });
    } else {
      console.error('Authentication provider was still null.');
      setCurrentView(VIEWS_ENUM.ERROR_VIEW);
    }
  };

  useEffect(() => {
    keycloak.onTokenExpired = () => updateToken();

    keycloak
      .init({
        onLoad: 'login-required',
      })
      .then((authenticated) => {
        if (authenticated) {
          if (
            indexOf(
              get(keycloak, 'tokenParsed.realm_access.roles', []),
              SUBSCRIPTION_ROLE_ENUM.ENTERPRISE
            ) !== -1 &&
            get(keycloak, 'tokenParsed.organization', null) != null
          ) {
            setCurrentView(VIEWS_ENUM.COGUARD_ENTERPRISE);
          } else {
            setCurrentView(VIEWS_ENUM.FREE_OR_DEV);
          }
        } else {
          setCurrentView(VIEWS_ENUM.ERROR_VIEW);
        }
      })
      .catch((err) => {
        console.error('Could not initiate keycloak');
        setCurrentView(VIEWS_ENUM.ERROR_VIEW);
      });

    return () => {
      keycloak.onTokenExpired = null;
    };
  }, [keycloak]);

  const renderCurrentView = () => {
    switch (currentView) {
      case VIEWS_ENUM.COGUARD_ENTERPRISE:
        if (keycloak.tokenParsed != null) {
          return <CoGuardEnterprise authProvider={keycloak} />;
        }
        return (
          <div>
            <Menu changeGlobalView={(name) => changeGlobalView(name)} />
            <LoginPage />
          </div>
        );
      case VIEWS_ENUM.FREE_OR_DEV:
        if (keycloak.tokenParsed != null) {
          return <CoGuardEnterprise authProvider={keycloak} />;
        }
        return (
          <div>
            <Menu changeGlobalView={(name) => changeGlobalView(name)} />
            <LoginPage />
          </div>
        );
      case VIEWS_ENUM.ERROR_VIEW:
        return (
          <div
            className="menu
          AndContent"
          >
            <Menu changeGlobalView={(name) => changeGlobalView(name)} />
            <Alert severity="error">
              An error occurred when trying to authenticate. Please contact
              info@coguard.io for assistance.
            </Alert>
          </div>
        );
      default:
        return (
          <div className="menuAndContent">
            <Menu changeGlobalView={(name) => changeGlobalView(name)} />
            <p className="authInfoCheck">
              Checking possible authentication information... <br /> <br />
              If this prompt stays for more than a minute, please refresh the
              page.
            </p>
          </div>
        );
    }
  };

  ReactGA.send({
    hitType: 'pageview',
    page: window.location.href + window.location.search,
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider value={keycloak}>
          <>{renderCurrentView()}</>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
