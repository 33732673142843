import { isEmpty } from 'lodash';
import { useAuthProvider } from '../../AuthProvider';

export const useUserAccount = () => {
  const authProvider = useAuthProvider();
  if (!authProvider.tokenParsed) return [];

  const { email } = authProvider.tokenParsed;

  if (isEmpty(email)) {
    return [];
  }
  return [email];
};
