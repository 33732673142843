import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Box, LinearProgress, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router';
import { getSemanticPalette } from '../colors';
import { useSearchParams } from 'react-router-dom';
import { useClusterReportsList } from '../hooks/useClusterReportList.hook';
import { ReportPanel } from './ReportPanel';
import { ConfigPanel } from './ConfigPanel';
import { HistoryPanel } from './HistoryPanel';
import { useClusterManifest } from '../hooks/useClusterManifest.hook';
import { FilePanel } from './FilePanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CoGuardClusterView = () => {
  const { clusterName, organizationName } = useParams();
  const clusterReports = useClusterReportsList(organizationName, clusterName);
  const clusterManifest = useClusterManifest(organizationName, clusterName);
  const reportIds = useMemo(
    () => (clusterReports ? clusterReports.filter((itm) => !isEmpty(itm)) : []),
    [clusterReports]
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Reports',
        key: 'reports',
        component: !isEmpty(reportIds) ? (
          <ReportPanel clusterReports={reportIds} />
        ) : (
          <LinearProgress />
        ),
        disabled: false,
      },
      {
        label: 'History',
        key: 'history',
        component: !isEmpty(reportIds) ? (
          <HistoryPanel
            organizationName={organizationName}
            clusterName={clusterName}
            reportIds={reportIds}
          />
        ) : (
          <LinearProgress />
        ),
        disabled: false,
      },
      {
        label: 'Configuration',
        key: 'config',
        component: !isEmpty(clusterManifest) ? (
          <ConfigPanel clusterManifest={clusterManifest} />
        ) : (
          <LinearProgress />
        ),
        disabled: false,
      },
      {
        label: 'Files',
        key: 'files',
        component: !isEmpty(clusterManifest) ? (
          <FilePanel clusterManifest={clusterManifest} />
        ) : (
          <LinearProgress />
        ),
        disabled: false,
      },
    ],
    [reportIds, organizationName, clusterName, clusterManifest]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamTabIndex = tabs.findIndex(
    (tab) => tab.key === searchParams.get('tab')
  );
  const tabIndex = searchParamTabIndex === -1 ? 0 : searchParamTabIndex;
  const setTabIndex = (tabIndex) =>
    setSearchParams({ tab: tabs[tabIndex].key });
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const activeTab = useMemo(
    () => (tabs[tabIndex] ? tabs[tabIndex] : tabs[0]),
    [tabs, tabIndex]
  );

  return (
    <Box>
      <h3>{clusterName}</h3>
      <Box borderBottom={`1px solid ${getSemanticPalette('light').neutral}`}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, i) => (
            <Tab
              key={i}
              label={tab.label}
              {...a11yProps(i)}
              disabled={tab.disabled}
            />
          ))}
        </Tabs>

        <Box>{activeTab.component}</Box>
      </Box>
    </Box>
  );
};
