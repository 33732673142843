import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { get, reduce, map, sortBy, sortedIndexOf } from 'lodash';
import { useToken } from '../hooks/useToken.hook';
import ApiConnection from '../../apiConnection/apiConnection';

export const CoGuardAdminView = () => {
  const [orgList, setOrgList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [orgUserList, setOrgUserList] = useState([]);
  const [orgSummaries, setOrgSummaries] = useState({});
  const [freeScans, setFreeScans] = useState({});
  const [freeScansByMonth, setFreeScansByMonth] = useState({});
  const [enterpriseScansByMonth, setEnterpriseScansByMonth] = useState({});
  const [registrationPromptCount, setRegistrationPromptCount] = useState(0);
  const token = useToken();

  useEffect(() => {
    if (token) {
      Promise.all([
        ApiConnection.getOrgList(token),
        ApiConnection.getUserList(token),
        ApiConnection.getNonFreeUserList(token),
        ApiConnection.getOrgSummaries(token),
        ApiConnection.getFreeScanNumbers(token),
        ApiConnection.getLogMessageCount('REGISTRATION_PROMPT', token),
        ApiConnection.getFreeScanNumbersByMonth(token),
        ApiConnection.getEnterpriseScanNumbersByMonth(token),
      ]).then(
        ([
          resOrgList,
          resUserList,
          resOrgUserList,
          resOrgSummaries,
          resFreeScans,
          resRegPrompts,
          resFreeByMonth,
          resEnterpriseByMonth,
        ]) => {
          setOrgList(resOrgList);
          setUserList(resUserList);
          setOrgUserList(sortBy(map(resOrgUserList, 'username')));
          setOrgSummaries(resOrgSummaries);
          setFreeScans(resFreeScans);
          setRegistrationPromptCount(resRegPrompts);
          setFreeScansByMonth(resFreeByMonth);
          setEnterpriseScansByMonth(resEnterpriseByMonth);
        }
      );
    }
  }, [token]);

  const downloadHandle = (apiCallPromise, ident) => {
    apiCallPromise.then((blob) => {
      const fileBlob = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = fileBlob;
      a.download = `${ident}_data_${new Date().getTime()}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };

  return (
    <Box>
      <h1>Organization List</h1>
      <ol>
        {orgList.map((orgName) => (
          <li key={orgName}>{orgName}</li>
        ))}
      </ol>
      <h1>User List</h1>
      <p>
        <b>TOTAL: </b> {userList.length}
      </p>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="User Table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Sign Up Date</TableCell>
              <TableCell>Free User</TableCell>
              <TableCell>Viewed Demo</TableCell>
              <TableCell>Number Of Scans</TableCell>
              <TableCell>Last Scan</TableCell>
              <TableCell>Days since Last Scan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList
              .sort((row1, row2) => {
                if (row1.createdTimestamp < row2.createdTimestamp) {
                  return 1;
                } else if (row1.createdTimestamp > row2.createdTimestamp) {
                  return -1;
                }
                return 0;
              })
              .map((row) => {
                const createdTimestampDate = new Date(row.createdTimestamp);
                const createdTimestampString = `${createdTimestampDate.getDate()}/${
                  createdTimestampDate.getMonth() + 1
                }/${createdTimestampDate.getFullYear()}`;
                const lastScanDate = new Date(
                  Number(get(row, 'lastScan', 0)) * 1000
                );
                const lastScanDateString = `${lastScanDate.getDate()}/${
                  lastScanDate.getMonth() + 1
                }/${lastScanDate.getFullYear()}`;
                return (
                  <TableRow
                    key={row.username}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {get(row, 'firstName', '')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {get(row, 'lastName', '')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell>{createdTimestampString}</TableCell>
                    <TableCell>
                      {sortedIndexOf(orgUserList, row.username) === -1
                        ? 'Yes'
                        : 'No'}
                    </TableCell>
                    <TableCell>
                      {get(row, 'isDemo', false) ? 'Yes' : ''}
                    </TableCell>
                    <TableCell>{get(row, 'numberOfScans', 0)}</TableCell>
                    <TableCell>
                      {get(row, 'lastScan', 0) === 0 ? '' : lastScanDateString}
                    </TableCell>
                    <TableCell>
                      {get(row, 'lastScan', 0) === 0
                        ? ''
                        : Math.round(
                            Math.abs(
                              (new Date() - lastScanDate) / (86400 * 1000)
                            )
                          )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <h1>Total scans:</h1>
      <ul>
        <li>
          Enterprise customers:{' '}
          {reduce(
            orgSummaries,
            (result, clusterMap) =>
              result +
              reduce(
                clusterMap,
                (clusterRes, reportMap) =>
                  clusterRes + get(reportMap, 'totalReports', 0),
                0
              ),
            0
          )}
        </li>
        <li>
          Free customers:{' '}
          {reduce(freeScans, (result, value) => result + value, 0)}
        </li>
      </ul>
      <p
        style={{ cursor: 'pointer' }}
        onClick={() =>
          downloadHandle(ApiConnection.downloadUserCsv(token), 'user')
        }
      >
        DOWNLOAD USER SCAN CSV
      </p>
      <p
        style={{ cursor: 'pointer' }}
        onClick={() =>
          downloadHandle(ApiConnection.downloadOrgCsv(token), 'org')
        }
      >
        DOWNLOAD ORG SCAN CSV
      </p>
      <h1>Scans By Month</h1>
      <ul>
        <li>Enterprise Customers: {JSON.stringify(enterpriseScansByMonth)}</li>
        <li>Free Users: {JSON.stringify(freeScansByMonth)}</li>
      </ul>
      <h1>CLI registration prompts</h1>
      <p>Total registration prompts: {registrationPromptCount}</p>
    </Box>
  );
};
