import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from '@mui/material';
import { SeverityLevelsDescriptions } from '../../util';

function createData(name, items, color) {
  return { name, items, color };
}

const severityMap = SeverityLevelsDescriptions;
const severities = Object.keys(severityMap);

const rows = severities.map((severityKey) =>
  createData(
    severityMap[severityKey].label,
    severityMap[severityKey].description,
    severityMap[severityKey].color
  )
);

export const SeverityLevelsTable = () => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Severity</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="left">
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ name, items, color }) => {
            return (
              <TableRow key={name}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    minWidth: '150px',
                    borderLeftStyle: 'solid',
                    borderLeftColor: color,
                    fontWeight: 'bold',
                  }}
                >
                  {name}
                </TableCell>
                <TableCell align="left">
                  {items.map((item) => (
                    <div key={item}>{item}</div>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
