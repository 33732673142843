const supportedConfigFileTypes = [
  'json',
  'ini',
  'krb',
  'nginx',
  'yaml',
  'properties',
  'pg_hba',
  'xml',
  'httpd',
  'custom',
  'aws_cfn',
  'dockerfile',
  'hcl2',
  'mysql',
  'redis',
  'toml',
];

export default supportedConfigFileTypes;
