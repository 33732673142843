/**
 * This module contains the configuration singelton for this project
 */

/*
 * The main singleton class
 */
class Configuration {
  constructor() {
    if (!Configuration.instance) {
      this.data = {
        clientPort: '443',
        clientUrl: 'https://test.coguard.io',
        apiPort: null,
        apiHost: 'https://test.coguard.io/server',
        stripePublicKey:
          'pk_live_51HsXfsGl0NQvGF2ByMtlKermKZippREpaM9IAhT7Bn10x4CCM4W7bcTaLPeqFWHxL67UaHY5vXbKgCr16BDKZ7Ij00YNLbokjO',
        keycloakConf: {
          realm: 'coguard',
          url: 'https://test.coguard.io/auth',
          clientId: 'client-react-frontend',
        },
      };
      Configuration.instance = this;
    }
    return Configuration.instance;
  }
}

const instance = new Configuration();
Object.freeze(instance);

export default instance;
