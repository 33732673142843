import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import Alert from '@mui/material/Alert';
import { isEmpty } from 'lodash';
import ApiConnection from '../../apiConnection/apiConnection';

function AddCluster(props) {
  const [clusterName, setClusterName] = useState('');
  const [location, setLocation] = useState('');
  const [clusterType, setClusterType] = useState('');
  const [derivedFrom, setDerivedFrom] = useState('');
  const [errorPresent, setErrorPresent] = useState(false);

  const handleClose = () => {
    props.onClose();
  };

  const handleAddCluster = () => {
    return ApiConnection.addCluster(
      props.organizationName,
      props.authProvider.token,
      clusterName,
      location,
      clusterType,
      derivedFrom
    )
      .then(() => {
        handleClose();
        setErrorPresent(false);
        props.resetClusterJsons();
      })
      .catch(() => {
        setErrorPresent(true);
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      fullWidth
      data-testid="Add Cluster Dialog"
      maxWidth="lg"
    >
      <DialogTitle>Add a new cluster</DialogTitle>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        style={{ minHeight: 400 }}
      >
        <Paper
          elevation={3}
          style={{ width: 600, minHeight: 300, padding: '10px' }}
        >
          {errorPresent ? (
            <Alert severity="error">Failed to add this cluster</Alert>
          ) : (
            ''
          )}
          <TextField
            label="Cluster Name"
            aria-label="Cluster Name Add Cluster"
            value={clusterName}
            onChange={(evt) => setClusterName(evt.target.value)}
          />
          <br />
          <TextField
            label="Location"
            aria-label="Location Add Cluster"
            value={location}
            onChange={(evt) => setLocation(evt.target.value)}
          />
          <br />
          <FormControl>
            <InputLabel>Type of cluster</InputLabel>
            <Select
              value={clusterType}
              onChange={(evt) => setClusterType(evt.target.value)}
              inputProps={{ 'data-testid': 'Cluster Hosted On' }}
              style={{ width: '25ch' }}
            >
              <MenuItem value="AWS" data-testid="AWS MenuItem">
                AWS
              </MenuItem>
              <MenuItem value="GCP" data-testid="GCP MenuItem">
                Google Cloud
              </MenuItem>
              <MenuItem value="AZURE" data-testid="AZURE MenuItem">
                Microsoft Azure
              </MenuItem>
              <MenuItem value="ON_PREM" data-testid="ON_PREM MenuItem">
                On Premise
              </MenuItem>
              <MenuItem value="UNKNOWN" data-testid="UNKNOWN MenuItem">
                Unknown
              </MenuItem>
            </Select>
          </FormControl>
          <br />
          <FormControl>
            <InputLabel>Type of IaC Tool</InputLabel>
            <Select
              value={derivedFrom}
              onChange={(evt) => setDerivedFrom(evt.target.value)}
              style={{ width: '25ch' }}
              inputProps={{ 'data-testid': 'Cluster Derived From' }}
            >
              <MenuItem value="TERRAFORM" data-testid="TERRAFORM MenuItem">
                Terraform
              </MenuItem>
              <MenuItem
                value="CLOUDFORMATION"
                data-testid="CLOUDFORMATION MenuItem"
              >
                CloudFormation
              </MenuItem>
              <MenuItem
                value="AZURE_RESOURCE_MANAGER"
                data-testid="AZURE_RESOURCE_MANAGER MenuItem"
              >
                Azure Resource Manager
              </MenuItem>
              <MenuItem value="MANUAL" data-testid="MANUAL MenuItem">
                Manual
              </MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          <center>
            <Fab
              color="primary"
              aria-label="Add Cluster Submit"
              onClick={handleAddCluster}
              disabled={isEmpty(clusterName)}
            >
              <AddIcon />
            </Fab>
          </center>
        </Paper>
      </Grid>
    </Dialog>
  );
}

export default AddCluster;
