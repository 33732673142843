import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import AddBoxIcon from '@mui/icons-material/AddBox';
import validator from 'validator';
import ApiConnection from '../../apiConnection/apiConnection';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty } from 'lodash';

function AddCidrRow(props) {
  const [priority, setPriority] = useState('');
  const [protocol, setProtocol] = useState('');
  const [cidrBlock, setCidrBlock] = useState('');
  const [beginPort, setBeginPort] = useState('');
  const [endPort, setEndPort] = useState('');

  const newRuleCanBeAdded = () => {
    let result = true;
    result = result && priority >= 0;
    result = result && !isEmpty(protocol);
    result = result && !isEmpty(cidrBlock);
    result = result && beginPort > 0;
    result = result && endPort > 0;
    result = result && beginPort < 65536;
    result = result && endPort < 65536;
    const cidrSplit = cidrBlock.split('/');
    result = result && cidrSplit.length === 2 && validator.isIP(cidrSplit[0]);
    return result;
  };

  const handleCreateNewCidrRule = () => {
    ApiConnection.postCidrRule(
      props.organizationName,
      props.authProvider.token,
      props.clusterName,
      props.machineId,
      props.direction,
      parseInt(priority),
      protocol,
      cidrBlock,
      parseInt(beginPort),
      parseInt(endPort)
    )
      .then(() => {
        props.setErrorMessage('');
        props.resetClusterJsons('');
      })
      .catch((err) => {
        props.setErrorMessage('Failed to create new CIDR rule.');
      });
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell style={{ border: '1px solid #ddd' }}>
        <TextField
          value={priority}
          onChange={(evt) => setPriority(evt.target.value)}
          type="number"
          inputProps={{ 'data-testid': 'Priority table cell' }}
        />
      </TableCell>
      <TableCell style={{ border: '1px solid #ddd' }} align="right">
        <Select
          value={protocol}
          onChange={(evt) => setProtocol(evt.target.value)}
          inputProps={{ 'data-testid': 'Protocol table cell' }}
        >
          <MenuItem value="TCP">TCP</MenuItem>
          <MenuItem value="UDP">UDP</MenuItem>
          <MenuItem value="ANY">ANY</MenuItem>
          <MenuItem value="OTHER">OTHER</MenuItem>
        </Select>
      </TableCell>
      <TableCell style={{ border: '1px solid #ddd' }} align="right">
        <TextField
          value={cidrBlock}
          onChange={(evt) => setCidrBlock(evt.target.value)}
          inputProps={{ 'data-testid': 'CIDR table cell' }}
        />
      </TableCell>
      <TableCell style={{ border: '1px solid #ddd' }} align="right">
        <TextField
          value={beginPort}
          onChange={(evt) => setBeginPort(evt.target.value)}
          type="number"
          inputProps={{ 'data-testid': 'Begin Port table cell' }}
        />
      </TableCell>
      <TableCell style={{ border: '1px solid #ddd' }} align="right">
        <TextField
          value={endPort}
          onChange={(evt) => setEndPort(evt.target.value)}
          type="number"
          inputProps={{ 'data-testid': 'End Port table cell' }}
        />
      </TableCell>
      <TableCell>
        <IconButton
          color="primary"
          disabled={!newRuleCanBeAdded()}
          aria-label="Add New Config File Button"
          onClick={handleCreateNewCidrRule}
        >
          <AddBoxIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default AddCidrRow;
