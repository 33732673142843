import React, { useState } from 'react';
import { Box, Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuthProvider } from '../../AuthProvider';
import { has, get, isEmpty } from 'lodash';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import ApiConnection from '../../apiConnection/apiConnection';
import vantaLogo from './img/vanta_logo.jpg';
import Alert from '@mui/material/Alert';

export const CoGuardConnectAccountsView = () => {
  const [error, setError] = useState('');
  const [connectionSuccess, setConnectionSuccess] = useState('');
  const [stateSecret, setStateSecret] = useState('');
  const [isVantaConnected, setIsVantaConnected] = useState(false);
  const authProvider = useAuthProvider();

  const routeParams = useParams();
  let organizationName;
  const curUrl = new URL(window.location.href);
  const queryParams = new URLSearchParams(curUrl.search);
  const stateParam = queryParams.get('state');
  if (has(routeParams, 'organizationName')) {
    organizationName = get(routeParams, 'organizationName');
  } else {
    if (stateParam != null) {
      organizationName = stateParam.split(';')[0];
    } else {
      return (
        <Box>
          <p>Invalid call parameters.</p>
        </Box>
      );
    }
  }
  const token = authProvider.token;

  if (!isVantaConnected) {
    ApiConnection.isVantaConnected(organizationName, token).then((result) => {
      if (result.toLowerCase() === 'true') {
        setIsVantaConnected(true);
      }
    });
  }

  const clientId = 'vci_d06b2926d38b3a8094ca2f210ec07d10f8074e53cdf7c87d';
  if (isEmpty(stateSecret)) {
    if (localStorage.getItem(`${organizationName}-vanta-state`)) {
      setStateSecret(localStorage.getItem(`${organizationName}-vanta-state`));
    } else {
      const stateSecretValue = `${organizationName};${crypto.randomUUID()}`;
      localStorage.setItem(`${organizationName}-vanta-state`, stateSecretValue);
      setStateSecret(stateSecretValue);
    }
    return (
      <Box>
        <p>Loading.</p>
      </Box>
    );
  }
  const redirectUrl = 'http://localhost:3000/connect-accounts';

  if (queryParams.get('code') != null && stateParam != null) {
    if (stateParam != stateSecret) {
      if (isEmpty(error)) {
        setError('The provided and received code did not match.');
      }
    } else {
      localStorage.removeItem(`${organizationName}-vanta-state`);
      ApiConnection.connectVanta(
        organizationName,
        queryParams.get('code'),
        token
      ).then(() => {
        if (isEmpty(connectionSuccess)) {
          setConnectionSuccess('Successfully connected Vanta.');
        }
      });
    }
  }

  return (
    <Box>
      {!isEmpty(error) && <Alert severity="error">{error}</Alert>}
      {!isEmpty(connectionSuccess) && (
        <Alert severity="success">{connectionSuccess}</Alert>
      )}
      <Card height="100vh" display="flex">
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Vanta
          </Typography>
          <img src={vantaLogo} alt="Vanta Logo" />
        </CardContent>
        <CardActions>
          {isVantaConnected ? (
            <Button variant="contained" disabled>
              Vanta already connected
            </Button>
          ) : (
            <Button
              href={
                `https://app.vanta.com/oauth/authorize?client_id=${clientId}` +
                '&scope=connectors.self:write-resource%20connectors.self:read-resource' +
                `&state=${stateSecret}&redirect_uri=${encodeURIComponent(
                  redirectUrl
                )}` +
                `&source_id=${organizationName}&response_type=code`
              }
              variant="contained"
            >
              Connect Vanta
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};
