import config from '../config/configuration';

/**
 * The main class abstraction to contact the API and perform certain operations on the output.
 */
class ApiConnection {
  static hostPort = `${config.data.apiHost}${
    config.data.apiPort == null ? '' : ':' + config.data.apiPort
  }`;

  /**
   * This function returns a promise of a call to the api that returns a
   * list of different clusters as registered for a certain organization.
   */
  static getClusterListPromise(organizationName, token) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/list?organizationName=${organizationName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get cluster list.');
    });
  }

  /**
   * This function returns a promise of a call to get the manifest object from the api
   * for a specific cluster in an organization.
   */
  static getClusterManifest(organizationName, token, clusterName) {
    return fetch(
      `${ApiConnection.hostPort}/cluster?organizationName=${organizationName}&clusterName=${clusterName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(`Could not get cluster manifest for ${clusterName}`);
    });
  }

  /**
   * This function returns a promise of a call to get the manifest object from the api
   * for a specific cluster.
   */
  static getCliClusterManifest(userName, token, clusterName) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/cluster?userName=${userName}&clusterName=${clusterName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(`Could not get cluster manifest for ${clusterName}`);
    });
  }

  /**
   * This function returns a promise of a call to get the list of reports for a specific cluster
   * from the api.
   */
  static getClusterReportList(organizationName, token, clusterName) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/reports/list?organizationName=${organizationName}&clusterName=${clusterName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(`Could not get a report list for ${clusterName}`);
    });
  }

  /**
   * This function returns a promise of a call to get the Json for a report of a cluster
   * from the api.
   */
  static getClusterReportJson(
    organizationName,
    token,
    clusterName,
    reportName
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/report?organizationName=${organizationName}&clusterName=${clusterName}&reportName=${reportName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(`Could not get report ${reportName} for ${clusterName}`);
    });
  }

  /**
   * This function returns a promise of a call to get the Json for a report of a cluster
   * from the api.
   */
  static getCliClusterReportJson(userName, token, clusterName, reportName) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/cluster/report?userName=${userName}&clusterName=${clusterName}&reportName=${reportName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(`Could not get report ${reportName} for ${clusterName}`);
    });
  }

  /**
   * This function returns a promise of a call to get the summary (total issues) of a cluster report
   * from the api.
   */
  static getClusterReportJsonSummary(
    organizationName,
    token,
    clusterName,
    reportName
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/reportSummary?organizationName=${organizationName}&clusterName=${clusterName}&reportName=${reportName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(
        `Could not get report summary ${reportName} for ${clusterName}.`
      );
    });
  }

  /**
   * This function returns a promise of a call to get the summary (total issues) of a cluster report
   * from the api.
   */
  static getCliClusterReportJsonSummary(
    userName,
    token,
    clusterName,
    reportName
  ) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/cluster/reportSummary?userName=${userName}&clusterName=${clusterName}&reportName=${reportName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(
        `Could not get report summary ${reportName} for ${clusterName}.`
      );
    });
  }

  /**
   * This function returns a promise of a call to get the report audit zip
   * from the api.
   */
  static getClusterReportAuditZip(
    organizationName,
    token,
    clusterName,
    reportName
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/report-audit-zip?organizationName=${organizationName}&clusterName=${clusterName}&reportName=${reportName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.blob();
      }
      throw new Error(
        `Could not get report audit zip ${reportName} for ${clusterName}.`
      );
    });
  }

  /**
   * This function returns a promise of a call to get the report audit zip
   * from the api.
   */
  static getCliClusterReportAuditZip(userName, token, clusterName, reportName) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/report-audit-zip?userName=${userName}&clusterName=${clusterName}&reportName=${reportName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.blob();
      }
      throw new Error(
        `Could not get report audit zip ${reportName} for ${clusterName}.`
      );
    });
  }

  /**
   * The command to post a new machine to an existing cluster.
   * It consumes an organizationname, a clusterName and a body
   * with the details regarding the machine.
   */
  static addMachine(organizationName, token, clusterName, body) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/add-new-machine/` +
        `${clusterName}?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Could not add machine to cluster ${clusterName}.`);
    });
  }

  /**
   * The command to post a new service to a machine in an existing cluster.
   * It consumes an organizationname, a clusterName and a body
   * with the details regarding the machine.
   */
  static addService(organizationName, token, clusterName, machineId, body) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/add-new-service/` +
        `${clusterName}/${machineId}?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Could not add a service to ${machineId}.`);
    });
  }

  /**
   * The command to upload a configuration file to a corresponding entry.
   */
  static uploadConfigFile(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceKey,
    fileName,
    defaultFileName,
    subPath,
    body
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/upsert-config-file/` +
        `${clusterName}/${machineId}/${serviceKey}` +
        `?organizationName=${organizationName}` +
        `&fileName=${fileName}` +
        `&defaultFileName=${defaultFileName}` +
        `&subPath=${subPath}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
        body: body,
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not upload configuration file.');
    });
  }

  /**
   * The command to download a configuration file to a corresponding entry.
   */
  static downloadConfigFile(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceKey,
    fileName,
    subPath
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/download-machine-config/` +
        `${clusterName}/${machineId}/${serviceKey}/${fileName}` +
        `?organizationName=${organizationName}` +
        `&subPath=${subPath}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.blob();
      }
      throw new Error('Could not download configuration file.');
    });
  }

  /**
   * The command to upload a configuration file to a corresponding entry.
   */
  static uploadComplimentaryFile(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceKey,
    fileName,
    subPath,
    body,
    complimentaryFileType = 'custom'
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/upsert-complimentary-file/` +
        `${clusterName}/${machineId}/${serviceKey}` +
        `?organizationName=${organizationName}` +
        `&fileName=${fileName}` +
        `&subPath=${subPath}` +
        `&complimentaryFileType=${complimentaryFileType}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
        body: body,
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not upload complimentary file.');
    });
  }

  /**
   * The command to download a complimentary file to a corresponding entry.
   */
  static downloadComplimentaryFile(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceKey,
    fileName,
    subPath
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/download-machine-complimentary-file/` +
        `${clusterName}/${machineId}/${serviceKey}/${fileName}` +
        `?organizationName=${organizationName}` +
        `&subPath=${subPath}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.blob();
      }
      throw new Error('Could not download complimentary file.');
    });
  }

  /**
   * Creates an entry where a configuration file can be placed.
   */
  static upsertConfigFileEntry(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceKey,
    fileName,
    defaultFileName,
    subPath,
    aliasList,
    tags
  ) {
    const body = {
      fileName,
      defaultFileName,
      subPath,
      aliasList,
      tags,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/upsert-config-file-entry/` +
        `${clusterName}/${machineId}/${serviceKey}` +
        `?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not create the configuration file entry');
    });
  }

  /**
   * Creates an entry where a complimentary file can be placed.
   */
  static upsertComplimentaryFileEntry(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceKey,
    fileName,
    subPath,
    aliasList,
    tags,
    configurationFileType = 'custom'
  ) {
    const body = {
      fileName,
      subPath,
      aliasList,
      tags,
      configurationFileType,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/upsert-complimentary-file-entry/` +
        `${clusterName}/${machineId}/${serviceKey}` +
        `?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not create the configuration file entry');
    });
  }

  /**
   * Creates an entry where a complimentary file can be placed.
   */
  static upsertComplimentaryFileEntryClusterService(
    organizationName,
    token,
    clusterName,
    serviceKey,
    fileName,
    subPath,
    aliasList,
    tags,
    configurationFileType = 'custom'
  ) {
    const body = {
      fileName,
      subPath,
      aliasList,
      tags,
      configurationFileType,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/upsert-complimentary-file-entry-cluster-service/` +
        `${clusterName}/${serviceKey}` +
        `?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not create the complimentary file entry');
    });
  }

  /**
   * Runs the report.
   */
  static runReport(organizationName, token, clusterName) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/run-report/` +
        `${clusterName}?organizationName=${organizationName}`,
      {
        method: 'put',
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Running of report failed.');
    });
  }

  /**
   * Creates an entry where a configuration file can be placed.
   */
  static runDeriveCluster(organizationName, token, clusterName) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/run-cluster-derive/` +
        `${clusterName}?organizationName=${organizationName}`,
      {
        method: 'put',
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Deriving of cluster failed.');
    });
  }

  /**
   * Deletes a machine from the cluster
   */
  static deleteMachine(organizationName, token, clusterName, machineId) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/delete-machine/` +
        `${clusterName}/${machineId}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Deleting of machine ${machineId} failed.`);
    });
  }

  /**
   * Deletes a service from the cluster
   */
  static deleteService(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceId
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/delete-service/` +
        `${clusterName}/${machineId}/${serviceId}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Deletion of service ${serviceId} failed.`);
    });
  }

  /**
   * Deletes a config file from the cluster
   */
  static deleteStandardConfigFile(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceId,
    fileName,
    defaultFileName,
    subPath
  ) {
    const body = {
      fileName,
      defaultFileName,
      subPath,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/delete-config-file/` +
        `${clusterName}/${machineId}/${serviceId}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Deletion of ${defaultFileName} (${fileName}) failed.`);
    });
  }

  /**
   * Deletes a config file from the cluster
   */
  static deleteComplimentaryFile(
    organizationName,
    token,
    clusterName,
    machineId,
    serviceId,
    fileName,
    subPath
  ) {
    const body = {
      fileName,
      subPath,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/delete-complimentary-file/` +
        `${clusterName}/${machineId}/${serviceId}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Deletion of ${fileName} failed.`);
    });
  }

  /**
   * Deletes a complimentary file from the cluster
   */
  static deleteComplimentaryFileClusterService(
    organizationName,
    token,
    clusterName,
    serviceId,
    fileName,
    subPath
  ) {
    const body = {
      fileName,
      subPath,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/delete-complimentary-file-cluster-service/` +
        `${clusterName}/${serviceId}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Deletion of ${fileName} failed.`);
    });
  }

  /**
   * Deletes a machine from the cluster
   */
  static deleteCluster(organizationName, token, clusterName) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/delete-cluster/` +
        `${clusterName}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(
        'Could not authenticate given the authentication information.'
      );
    });
  }

  /**
   * Endpoint to add a cluster
   */
  static addCluster(
    organizationName,
    token,
    clusterName,
    location,
    clusterType,
    derivedFrom
  ) {
    const body = {
      location,
      clusterType,
      derivedFrom,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/add-cluster/` +
        `${clusterName}?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(
        'Could not authenticate given the authentication information.'
      );
    });
  }

  /**
   * The command to upload a configuration file to a corresponding entry.
   */
  static uploadConfigFileClusterService(
    organizationName,
    token,
    clusterName,
    serviceKey,
    fileName,
    defaultFileName,
    subPath,
    body
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/upsert-cluster-service-config-file/` +
        `${clusterName}/${serviceKey}` +
        `?organizationName=${organizationName}` +
        `&fileName=${fileName}` +
        `&defaultFileName=${defaultFileName}` +
        `&subPath=${subPath}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
        body: body,
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not upload configuration file.');
    });
  }

  /**
   * The command to upload a configuration file to a corresponding entry.
   */
  static downloadConfigFileClusterService(
    organizationName,
    token,
    clusterName,
    serviceKey,
    fileName,
    subPath
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/download-cluster-service-config/` +
        `${clusterName}/${serviceKey}/${fileName}` +
        `?organizationName=${organizationName}` +
        `&subPath=${subPath}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.blob();
      }
      throw new Error('Could not download configuration file.');
    });
  }

  /**
   * The command to upload a complimentary file to a corresponding entry.
   */
  static uploadComplimentaryFileClusterService(
    organizationName,
    token,
    clusterName,
    serviceKey,
    fileName,
    subPath,
    body
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/upsert-cluster-service-complimentary-file/` +
        `${clusterName}/${serviceKey}` +
        `?organizationName=${organizationName}` +
        `&fileName=${fileName}` +
        `&subPath=${subPath}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
        body: body,
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not upload complimentary file.');
    });
  }

  /**
   * The command to download a complimentary file to a corresponding entry.
   */
  static downloadComplimentaryFileClusterService(
    organizationName,
    token,
    clusterName,
    serviceKey,
    fileName,
    subPath
  ) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/download-cluster-service-complimentary-file/` +
        `${clusterName}/${serviceKey}/${fileName}` +
        `?organizationName=${organizationName}` +
        `&subPath=${subPath}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.blob();
      }
      throw new Error('Could not download complimentary file.');
    });
  }

  /**
   * Creates an entry where a configuration file can be placed.
   */
  static upsertConfigFileEntryClusterService(
    organizationName,
    token,
    clusterName,
    serviceKey,
    fileName,
    defaultFileName,
    subPath,
    aliasList,
    tags
  ) {
    const body = {
      fileName,
      defaultFileName,
      subPath,
      aliasList,
      tags,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/upsert-cluster-service-config-file-entry/` +
        `${clusterName}/${serviceKey}` +
        `?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not create the configuration file entry');
    });
  }

  /**
   * Deletes a config file from the cluster
   */
  static deleteStandardConfigFileClusterService(
    organizationName,
    token,
    clusterName,
    serviceId,
    fileName,
    defaultFileName,
    subPath
  ) {
    const body = {
      fileName,
      defaultFileName,
      subPath,
    };
    return fetch(
      `${ApiConnection.hostPort}/cluster/delete-cluster-config-file/` +
        `${clusterName}/${serviceId}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Deletion of ${defaultFileName} (${fileName}) failed.`);
    });
  }

  /**
   * Deletes a cluster service from the cluster
   */
  static deleteClusterService(organizationName, token, clusterName, serviceId) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/delete-cluster-service/` +
        `${clusterName}/${serviceId}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Deletion of service ${serviceId} failed.`);
    });
  }

  /**
   * The command to post a new service to a machine in an existing cluster.
   * It consumes an organizationname, a clusterName and a body
   * with the details regarding the machine.
   */
  static addClusterService(organizationName, token, clusterName, body) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/add-new-cluster-service/` +
        `${clusterName}?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error(`Could not add a service to cluster ${clusterName}.`);
    });
  }

  /**
   * The command to add a new CIDR rule to a machine in a specific direction.
   */
  static postCidrRule(
    organizationName,
    token,
    clusterName,
    machineId,
    direction,
    priority,
    protocol,
    cidrBlock,
    beginPort,
    endPort
  ) {
    const body = {
      priority,
      protocol,
      cidrBlock,
      beginPort,
      endPort,
    };
    return fetch(
      `${ApiConnection.hostPort}/add-new-cidr-rule/` +
        `${clusterName}/${machineId}/${direction}?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not post the new CIDR rule.');
    });
  }

  /**
   * The command to delete a CIDR rule from a machine in a specific direction.
   */
  static deleteCidrRule(
    organizationName,
    token,
    clusterName,
    machineId,
    direction,
    priority,
    protocol,
    cidrBlock,
    beginPort,
    endPort
  ) {
    const body = {
      priority,
      protocol,
      cidrBlock,
      beginPort,
      endPort,
    };
    return fetch(
      `${ApiConnection.hostPort}/delete-cidr-rule/` +
        `${clusterName}/${machineId}/${direction}?organizationName=${organizationName}`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not delete the provided CIDR block.');
    });
  }

  /**
   * This function returns a promise of a call to get the list of reports for a specific cluster
   * from the api.
   */
  static getCliClusterReportList(organizationName, token, clusterName) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/reports/list?userName=${organizationName}&clusterName=${clusterName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(`Could not get a report list for ${clusterName}`);
    });
  }

  /**
   * The command to get all CLI scan identifiers returned as list.
   */
  static getAllCliScansAsList(userName, token) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/get-scan-list` +
        `?userName=${userName}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not fetch a list of scans.');
    });
  }

  /**
   * The command to get all the results from a single CLI scan.
   */
  static getCliScanResult(scanId, userName, token) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/get-scan-result?scanId=${scanId}` +
        `&userName=${userName}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error(`Could not get the scan result for id ${scanId}.`);
    });
  }

  /**
   * Retrieve a summary object across all clusters of a given user.
   */
  static getUserSummary(userName, token) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/userSummary` +
        `?userName=${userName}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the user summary.');
    });
  }

  /**
   * Retrieve a summary object across all clusters of a given org.
   */
  static getOrgSummary(organizationName, token) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/orgSummary` +
        `?organizationName=${organizationName}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the organization summary.');
    });
  }

  // Section for admin-things

  static getOrgList(token) {
    return fetch(`${ApiConnection.hostPort}/internal/get-org-list`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the organization list.');
    });
  }

  static getUserList(token) {
    return fetch(`${ApiConnection.hostPort}/internal/get-user-list`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the user list.');
    });
  }

  static getNonFreeUserList(token) {
    return fetch(`${ApiConnection.hostPort}/internal/get-org-user-list`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the list of users belonging to an org.');
    });
  }

  static getOrgSummaries(token) {
    return fetch(`${ApiConnection.hostPort}/internal/get-org-summaries`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the organization summaries.');
    });
  }

  static getFreeScanNumbers(token) {
    return fetch(`${ApiConnection.hostPort}/internal/get-free-user-scans`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the organization summaries.');
    });
  }

  static getFreeScanNumbersByMonth(token) {
    return fetch(
      `${ApiConnection.hostPort}/internal/get-free-user-scans-by-month`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the free user scan numbers by month.');
    });
  }

  static getEnterpriseScanNumbersByMonth(token) {
    return fetch(
      `${ApiConnection.hostPort}/internal/get-enterprise-scans-by-month`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.json();
      }
      throw new Error('Could not get the enterprise scan numbers by month.');
    });
  }

  static connectVanta(organizationName, code, token) {
    const body = {
      code,
    };
    return fetch(
      `${ApiConnection.hostPort}/org/connect-vanta` +
        `?organizationName=${organizationName}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((results) => {
      if (results.ok) {
        return true;
      }
      throw new Error('Could not store the Vanta tokens.');
    });
  }

  static isVantaConnected(organizationName, token) {
    return fetch(
      `${ApiConnection.hostPort}/org/is-vanta-connected` +
        `?organizationName=${organizationName}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not retrieve information about Vanta connection.');
    });
  }

  static fillWithDefaultClusters(organizationName, token) {
    return fetch(
      `${ApiConnection.hostPort}/cluster/put-demo-clusters` +
        `?organizationName=${organizationName}`,
      {
        method: 'put',
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Failed to put new demo clusters out there.');
    });
  }

  static fillWithDefaultClustersCli(userName, token) {
    return fetch(
      `${ApiConnection.hostPort}/coguard-cli/cluster/put-demo-clusters` +
        `?userName=${userName}`,
      {
        method: 'put',
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Failed to put new demo clusters out there.');
    });
  }

  /**
   * The command to download the user csv.
   */
  static downloadUserCsv(token) {
    return fetch(`${ApiConnection.hostPort}/internal/user-csv`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${token}`,
      },
    }).then((results) => {
      if (results.ok) {
        return results.blob();
      }
      throw new Error('Could not download user csv file.');
    });
  }

  /**
   * The command to download the org csv.
   */
  static downloadOrgCsv(token) {
    return fetch(`${ApiConnection.hostPort}/internal/org-csv`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${token}`,
      },
    }).then((results) => {
      if (results.ok) {
        return results.blob();
      }
      throw new Error('Could not download org csv file.');
    });
  }

  static getLogMessageCount(message, token) {
    return fetch(
      `${ApiConnection.hostPort}/internal/log-message-count?message=${message}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((results) => {
      if (results.ok) {
        return results.text();
      }
      throw new Error('Could not get the registration prompt count.');
    });
  }
}

export default ApiConnection;
