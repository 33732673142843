import React, { useCallback, useMemo, useState } from 'react';
import { getLayoutPalette } from '../../../colors';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ActiveIssueBox } from './ActiveIssueBox';
import { sumIssuesBySeverityLevel } from '../../util';
import { getOrgsRoute } from '../../../routes';

const getClustersWithIssues = (clusterMap, severity) => {
  const clusterNames = Object.keys(clusterMap);
  const data = clusterNames.map((name) => {
    return [
      name,
      sumIssuesBySeverityLevel(clusterMap[name]['report'])[severity - 1],
    ];
  });
  const clustersWithIssues = data.filter(
    ([_, numOfIssuesForSeverity]) => numOfIssuesForSeverity > 0
  );
  clustersWithIssues.sort((tupleA, tupleB) => tupleB[1] - tupleA[1]);
  return clustersWithIssues;
};

export const ActiveIssueBoxWrapper = ({
  organizationName,
  clusterMap,
  title,
  value,
  color,
  addIcon,
  handleClickOpen,
  detailBoxExtendLeft,
  severity,
}) => {
  const data = useMemo(
    () => getClustersWithIssues(clusterMap, severity),
    [clusterMap, severity]
  );
  const [mouseOverIssueBox, setShowFloatingBox] = useState(false);
  const [mouseOverDetailBox, setMouseOverDetailBox] = useState(false);
  const onMouseOverIssueBox = useCallback(
    () => setShowFloatingBox(true),
    [setShowFloatingBox]
  );
  const onMouseLeaveIssueBox = useCallback(
    () => setShowFloatingBox(false),
    [setShowFloatingBox]
  );
  const onMouseOverDetailBox = useCallback(
    () => setMouseOverDetailBox(true),
    [setMouseOverDetailBox]
  );
  const onMouseLeaveDetailBox = useCallback(
    () => setMouseOverDetailBox(false),
    [setMouseOverDetailBox]
  );
  const layoutPalette = getLayoutPalette('default');
  const showFloatingBox = mouseOverIssueBox || mouseOverDetailBox;
  return (
    <Box>
      <Box
        style={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: layoutPalette.divider,
          display: showFloatingBox ? 'block' : 'none',
          position: 'absolute',
          width: '450px',
          maxHeight: '300px',
          backgroundColor: '#FFF',
          marginTop: '75px',
          zIndex: 1000,
          marginLeft: detailBoxExtendLeft ? '-350px' : '50px',
        }}
        onMouseEnter={onMouseOverDetailBox}
        onMouseLeave={onMouseLeaveDetailBox}
      >
        <Box style={{ overflowY: 'scroll', maxHeight: '300px' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Cluster</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Number of issues
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(([clusterName, numOfIssues]) => {
                  return (
                    <TableRow key={clusterName}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          minWidth: '150px',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        <Link to={getOrgsRoute(organizationName, clusterName)}>
                          {clusterName}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{numOfIssues}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <ActiveIssueBox
        key={title}
        label={title}
        value={value}
        color={color}
        addIcon={addIcon}
        onClick={handleClickOpen}
        onMouseEnter={onMouseOverIssueBox}
        onMouseLeave={onMouseLeaveIssueBox}
      />
    </Box>
  );
};
