import { Box, Grid } from '@mui/material';
import { Error, CheckCircle } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { getSemanticPalette } from '../../../colors';

export const ActiveIssueBox = ({
  label,
  value,
  color,
  addIcon,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const semanticPalette = getSemanticPalette('dark');
  const fontColor = value === 0 ? semanticPalette.positive : color;
  const _onMouseEnter = useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter();
    }
  }, [onMouseEnter]);
  const _onMouseLeave = useCallback(() => {
    if (onMouseLeave) {
      onMouseLeave();
    }
  }, [onMouseLeave]);
  return (
    <Grid
      item
      onClick={onClick}
      key={label}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      <Box
        key={'box'}
        style={{
          fontSize: '30px',
          textAlign: 'center',
          padding: '50px',
          paddingTop: '10px',
          paddingBottom: '10px',
          cursor: onClick ? 'pointer' : undefined,
        }}
      >
        <Box key={'label'} style={{ fontSize: '20px' }}>
          {label}
        </Box>
        <Box key={'value'} style={{ paddingBottom: '20px', color: fontColor }}>
          {value} {addIcon ? <Error /> : <></>}{' '}
          {value === 0 ? <CheckCircle /> : <></>}
        </Box>
      </Box>
    </Grid>
  );
};
