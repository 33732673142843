import React from 'react';
import { Grid } from '@mui/material';
import { Error } from '@mui/icons-material';
import { HeaderBox } from './HeaderBox';
import {
  getLastRanAsString,
  lastScanRanBeyondRecommendedTheshold,
} from '../../util';

const getTotalClusters = (clusterReportList) => {
  return Object.keys(clusterReportList).length;
};

export const HeaderBar = ({ clusterReportList, totalReports }) => {
  const totalClusters = getTotalClusters(clusterReportList);
  const lastRan = getLastRanAsString(clusterReportList);
  const addIconToLastRan =
    lastScanRanBeyondRecommendedTheshold(clusterReportList);
  return (
    <Grid container className={'HeaderBar'} justifyContent={'flex-end'}>
      <HeaderBox label={'Total Clusters'} value={totalClusters} />
      <HeaderBox label={'Total Reports'} value={totalReports} />
      <HeaderBox
        label={'Last Scan Ran'}
        value={lastRan}
        icon={addIconToLastRan ? <Error /> : undefined}
      />
    </Grid>
  );
};
