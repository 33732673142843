import React from 'react';
import './Menu.css';

/**
 * The global menu if the user is not logged into CoGuard enterprise.
 */
class MenuDefault extends React.Component {
  /**
   * The constructor that populates the global variable changeGlobalView
   * with the supplied function.
   */
  constructor(props) {
    super();
    this.changeGlobalView = props.changeGlobalView;
  }

  render() {
    return (
      <div className="menuWrapper">
        <div className="menu">
          <div className="menuRow">
            <div className="menuRow">
              <div className="menuItem" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuDefault;
