export const getOrgsRoute = (organizationName, clusterName) => {
  if (!organizationName) return '/orgs';
  if (!clusterName) return `/orgs/${organizationName}`;
  return `/orgs/${organizationName}/${clusterName}`;
};

export const getClustersRoute = () => '/clusters';

export const getReportsRoute = () => '/reports';

export const getAdminRoute = () => '/admin';

export const getConnectAccountsRoute = (organizationName) => {
  if (!organizationName) {
    return '/connect-accounts';
  }
  return `/connect-accounts/${organizationName}`;
};
