import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
} from '@mui/material';
import React, { useCallback } from 'react';
import { isEmpty, get } from 'lodash';
import { HistoricalReportsForCluster } from './components/HistoricalReportsForCluster';
import { getLabelForSeverity } from './util/SeverityLabelsUtil';
import { getDateTime } from '../utils/getDatetime';
import ApiConnection from '../../apiConnection/apiConnection';
import { useReportSummariesForCluster } from '../hooks/useReportSummariesForCluster';
import { useAuthProvider } from '../../AuthProvider';
import { styled } from '@mui/material/styles';

const DownloadLink = styled('div')({
  textDecoration: 'underline',
  cursor: 'pointer',
});

function downloadZipBlob(zipBlob, fileName) {
  const url = URL.createObjectURL(zipBlob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

const ReportTable = ({ reportsSummaries, downloadZip }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Report Date</strong>
          </TableCell>
          <TableCell>
            <strong>{getLabelForSeverity(5)}</strong>
          </TableCell>
          <TableCell>
            <strong>{getLabelForSeverity(4)}</strong>
          </TableCell>
          <TableCell>
            <strong>{getLabelForSeverity(3)}</strong>
          </TableCell>
          <TableCell>
            <strong>{getLabelForSeverity(2)}</strong>
          </TableCell>
          <TableCell>
            <strong>{getLabelForSeverity(1)}</strong>
          </TableCell>
          <TableCell>
            <strong>Total</strong>
          </TableCell>
          <TableCell>
            <strong>Score</strong>
          </TableCell>

          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {reportsSummaries.map(([reportId, summary], i) => (
          <TableRow key={i}>
            <TableCell>{getDateTime(Number(reportId))}</TableCell>
            <TableCell>{summary.sev5}</TableCell>
            <TableCell>{summary.sev4}</TableCell>
            <TableCell>{summary.sev3}</TableCell>
            <TableCell>{summary.sev2}</TableCell>
            <TableCell>{summary.sev1}</TableCell>
            <TableCell>
              {summary.sev1 +
                summary.sev2 +
                summary.sev3 +
                summary.sev4 +
                summary.sev5}
            </TableCell>
            <TableCell>{summary.score}</TableCell>
            <TableCell onClick={() => downloadZip(reportId)}>
              <DownloadLink>Download</DownloadLink>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const HISTOGRAM_CHART_LIMIT = 15;

export const HistoryPanel = ({ reportIds, organizationName, clusterName }) => {
  const authProvider = useAuthProvider();
  const token = authProvider.token;
  const isDefaultAccount = isEmpty(
    get(authProvider, ['tokenParsed', 'organization'], [])
  );
  const { reports: reportsSummaries } = useReportSummariesForCluster(
    organizationName,
    authProvider,
    reportIds,
    clusterName
  );
  const downloadZip = useCallback(
    (reportId) => {
      const clusterReportPromise = isDefaultAccount
        ? ApiConnection.getCliClusterReportAuditZip(
            organizationName,
            token,
            clusterName,
            reportId
          )
        : ApiConnection.getClusterReportAuditZip(
            organizationName,
            token,
            clusterName,
            reportId
          );
      clusterReportPromise.then((result) =>
        downloadZipBlob(
          result,
          `${organizationName}-${clusterName}-${getDateTime(
            Number(reportId)
          )}.zip`
        )
      );
    },
    [organizationName, token, clusterName, isDefaultAccount]
  );

  if (!reportsSummaries) return <LinearProgress />;

  const sortedReportsSummaries = [...reportsSummaries].sort(
    (report1, report2) => Number(report2[0]) - Number(report1[0])
  );
  const histogramReportSummaries = [
    ...sortedReportsSummaries.slice(0, HISTOGRAM_CHART_LIMIT),
  ].reverse();

  return (
    <Box sx={{ lineHeight: 3, width: '100%' }}>
      <Box>Total Reports: {reportsSummaries.length}</Box>
      <HistoricalReportsForCluster
        reportsSummaries={histogramReportSummaries}
      />
      <ReportTable
        reportsSummaries={sortedReportsSummaries}
        downloadZip={downloadZip}
      />
    </Box>
  );
};
