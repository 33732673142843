import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

/**
 *
 * @typedef {{ priority: number, protocol: string, cidrBlock: string, beginPort: number, endPort: number }} Rule
 */

const RuleTable = ({ rules }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Priority</TableCell>
        <TableCell>Protocol</TableCell>
        <TableCell>Source</TableCell>
        <TableCell>Begin Port</TableCell>
        <TableCell>End Port</TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {rules.map((rule, i) => (
        <TableRow key={i}>
          <TableCell>{rule.priority}</TableCell>
          <TableCell>{rule.protocol}</TableCell>
          <TableCell>{rule.cidrBlock}</TableCell>
          <TableCell>{rule.beginPort}</TableCell>
          <TableCell>{rule.endPort}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export const ConfigPanel = ({ clusterManifest }) => {
  /** @type Rule[] */
  const inboundRules = clusterManifest.inboundTraffic || [];
  /** @type Rule[] */
  const outboundRules = clusterManifest.outboundTraffic || [];
  return (
    <Box>
      <h4>Cluster</h4>
      <Table sx={{ width: 300 }}>
        <TableBody>
          <TableRow>
            <TableCell>Name:</TableCell>
            <TableCell>{clusterManifest.name || '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Location:</TableCell>
            <TableCell>{clusterManifest.location || '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Derived From:</TableCell>
            <TableCell>{clusterManifest.derivedFrom || '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type:</TableCell>
            <TableCell>{clusterManifest.clusterType || '-'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h4>Inbound Traffic Rules</h4>
      {inboundRules.length > 0 ? <RuleTable rules={inboundRules} /> : 'None'}

      <h4>Outbound Traffic Rules</h4>
      {outboundRules.length > 0 ? <RuleTable rules={outboundRules} /> : 'None'}
    </Box>
  );
};
