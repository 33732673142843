import React, { useMemo, useState, useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { getBadPalette } from '../../../colors';
import { getLabelForSeverity } from '../../util/SeverityLabelsUtil';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { SeverityLevelsTable } from './SeverityLevelsTable';
import { ActiveIssueBoxWrapper } from './ActiveIssueBoxWrapper';

const SEVERITY_5_INDEX = 4;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const clusterMapToScorecardData = (clusterMap, colorPalette) => {
  const clusterNames = Object.keys(clusterMap);
  const seriesTitles = [
    getLabelForSeverity(1),
    getLabelForSeverity(2),
    getLabelForSeverity(3),
    getLabelForSeverity(4),
    getLabelForSeverity(5),
  ];
  const seriesColorsKeys = ['bad1', 'bad2', 'bad3', 'bad4', 'bad5'];
  const seriesColors = seriesColorsKeys.map((key) => colorPalette[key]);
  const accumulator = [0, 0, 0, 0, 0];
  const seriesValues = clusterNames.map((name) => {
    return clusterMap[name]['report'];
  });
  seriesValues
    .filter((totalsMap) => !isEmpty(totalsMap))
    .forEach((totalsMap) => {
      accumulator[0] = accumulator[0] + totalsMap.sev1;
      accumulator[1] = accumulator[1] + totalsMap.sev2;
      accumulator[2] = accumulator[2] + totalsMap.sev3;
      accumulator[3] = accumulator[3] + totalsMap.sev4;
      accumulator[4] = accumulator[4] + totalsMap.sev5;
    });
  const result = seriesTitles.map((title, i) => [
    title,
    accumulator[i],
    seriesColors[i],
    // Only add an icon for Issues of Severity 5 if there's one or more active issues
    i === SEVERITY_5_INDEX && accumulator[i] > 0,
    //Severity
    i + 1,
  ]);
  return result.reverse();
};

export const ActiveIssues = ({ organizationName, clusterMap }) => {
  const paletteMap = getBadPalette('badPalette1');
  const [open, setOpen] = useState(false);
  const data = useMemo(
    () => clusterMapToScorecardData(clusterMap, paletteMap),
    [clusterMap, paletteMap]
  );
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const modalTitle = 'Severity Levels';

  return (
    <>
      <Grid container direction={'column'}>
        <Grid item xs={2}>
          <Box style={{ fontSize: '30px', textAlign: 'left', padding: '20px' }}>
            <Box
              style={{
                fontSize: '20px',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={handleClickOpen}
            >
              Active Issues
            </Box>
          </Box>
        </Grid>
        <Grid container justifyContent={'space-evenly'}>
          {data.map(([title, value, color, addIcon, severity], index) => (
            <Box key={index}>
              <ActiveIssueBoxWrapper
                organizationName={organizationName}
                clusterMap={clusterMap}
                title={title}
                value={value}
                color={color}
                addIcon={addIcon}
                detailBoxExtendLeft={index >= 3}
                severity={severity}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        maxWidth={'md'}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <SeverityLevelsTable />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
