function daysBetweenTodayAndDate(date) {
  const today = new Date(Date.now());
  const todayUTC = Date.UTC(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate()
  );
  const providedDateUTC = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  );
  const diffInDays = Math.floor(
    (providedDateUTC - todayUTC) / (1000 * 60 * 60 * 24)
  );
  return -1 * diffInDays;
}

export const getDaysAgo = (timestampSec) => {
  const timestampMs = Number(timestampSec) * 1000;
  const date = new Date(timestampMs);

  return daysBetweenTodayAndDate(date);
};
