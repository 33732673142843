import React from 'react';
import { Box, Card, LinearProgress } from '@mui/material';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import { useOrganizationSummary } from '../hooks';
import { useOrganizations } from '../hooks/useOrganizations.hook';
import { useUserSummary } from '../hooks';
import { isEmpty, mapValues, startsWith, some, map } from 'lodash';
import { getLabelForSeverity } from './util/SeverityLabelsUtil';
import { getBadPalette, getSemanticPalette } from '../colors';
import { getDaysAgo } from '../utils/getDaysAgo';
import { Link, useParams } from 'react-router-dom';
import { getOrgsRoute } from '../routes';
import ApiConnection from '../../apiConnection/apiConnection';
import { HeaderBar } from './components/HeaderBar/HeaderBar';
import { ActiveIssues } from './components/ActiveIssues';
import { FailedChecksByCluster } from './components/FailedChecksByCluster';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import { useAuthProvider } from '../../AuthProvider';

const Section = ({ children }) => (
  <Box borderBottom={`1px solid ${getSemanticPalette('light').neutral}`}>
    {children}
  </Box>
);

const Title = ({ children }) => (
  <Box fontWeight="bold" style={{ textDecoration: 'underline' }}>
    {children}
  </Box>
);

const SeverityItem = ({ children, color }) => (
  <Box marginRight="12px" color={color} style={{ textDecoration: 'underline' }}>
    {children}
  </Box>
);

const ClusterRow = ({ children }) => (
  <Box paddingBottom="10px" display="flex" alignItems="center">
    {children}
  </Box>
);

const ClusterItem = ({ organizationName, clusterName, clusterItem }) => {
  const [
    severity1Count,
    severity2Count,
    severity3Count,
    severity4Count,
    severity5Count,
  ] = [
    clusterItem.report ? clusterItem.report.sev1 : 0,
    clusterItem.report ? clusterItem.report.sev2 : 0,
    clusterItem.report ? clusterItem.report.sev3 : 0,
    clusterItem.report ? clusterItem.report.sev4 : 0,
    clusterItem.report ? clusterItem.report.sev5 : 0,
  ];
  const palette = getBadPalette('badPalette1');
  return (
    <Box padding="8px">
      <ClusterRow>
        <DnsRoundedIcon />
        <Title>
          <Link to={getOrgsRoute(organizationName, clusterName)}>
            {clusterName}
          </Link>
        </Title>
      </ClusterRow>
      <ClusterRow>
        <SeverityItem color={palette.bad5}>
          {getLabelForSeverity(5)} {severity5Count}
        </SeverityItem>
        <SeverityItem color={palette.bad4}>
          {getLabelForSeverity(4)} {severity4Count}
        </SeverityItem>
        <SeverityItem color={palette.bad3}>
          {getLabelForSeverity(3)} {severity3Count}
        </SeverityItem>
        <SeverityItem color={palette.bad2}>
          {getLabelForSeverity(2)} {severity2Count}
        </SeverityItem>
        <SeverityItem color={palette.bad1}>
          {getLabelForSeverity(1)} {severity1Count}
        </SeverityItem>
      </ClusterRow>
      {clusterItem.timestamp ? (
        <ClusterRow>
          Last run: {getDaysAgo(clusterItem.timestamp)} days ago
        </ClusterRow>
      ) : (
        <ClusterRow>Not run yet.</ClusterRow>
      )}
    </Box>
  );
};

const coguardCommonSummaryView = (
  organizationName,
  summary,
  open,
  setOpen,
  populateOpen,
  setPopulateOpen,
  isOrg,
  token
) => {
  if (!summary) {
    return (
      <Box style={{ textAlign: 'center', marginTop: '40px' }}>
        <LinearProgress />
      </Box>
    );
  }

  const { clusterCount, clusterMap, totalReports } = summary;
  const isDemoCluster = some(
    map(Object.keys(clusterMap), (clusterName) => {
      return startsWith(clusterName, 'coguard_demo');
    })
  );
  const clusterReportList = mapValues(clusterMap, (val) => val['timestamp']);

  if (!clusterMap) {
    return (
      <Box style={{ textAlign: 'center', marginTop: '40px' }}>
        <LinearProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Collapse in={open && (isDemoCluster || clusterCount === 0)}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>Welcome to CoGuard</AlertTitle>
          <p>
            CoGuard works best from the command line. The CoGuard CLI is
            available for installation on macOS, Windows and Linux environments.
            We do not have a Docker image as we are also analyzing referenced
            Docker images, which would require the user to run the CoGuard
            container with extensive privileges that are not recommended from a
            best-practice perspective.
          </p>
          <ul>
            <li>
              <a
                href="https://github.com/coguardio/coguard-cli#installation-instructions"
                target="_blank"
                rel="noreferrer"
              >
                CLI Installation instructions
              </a>
            </li>
          </ul>
          <p>
            Still have questions?{' '}
            <a
              href="https://calendly.com/coguard/30min"
              target="_blank"
              rel="noreferrer"
            >
              Schedule a walkthrough
            </a>{' '}
            with our team to show you CoGuard in action.
          </p>
        </Alert>
      </Collapse>
      <Collapse in={populateOpen && !isDemoCluster && clusterCount === 0}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPopulateOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>CoGuard Quickstart</AlertTitle>
          <p>
            This is a sample cluster that you can use to experience CoGuard
            configuration file scanner. The sample cluster includes code
            repositories for a web application that includes Dockerhub
            containers for httpd, mysql, nginx and Panethon hosted postgres.
          </p>
          <p>
            You can explore the provided repositories and the results of the
            CoGuard Engine.
          </p>
          <p>Note: You can delete the QuickStart samples at any time.</p>
          <Button
            variant="contained"
            onClick={() => {
              const callPromise = isOrg
                ? ApiConnection.fillWithDefaultClusters(organizationName, token)
                : ApiConnection.fillWithDefaultClustersCli(
                    organizationName,
                    token
                  );
              return callPromise.then(() => window.location.reload());
            }}
          >
            Add Quickstart samples
          </Button>
        </Alert>
      </Collapse>
      <Collapse in={populateOpen && isDemoCluster}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPopulateOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>Quickstart added</AlertTitle>
          <p>
            {clusterCount} demo clusters have been added. The clusters contain a
            variety of web and cloud native projects. CoGuard configuration
            reports are included for all clusters.
          </p>
          <p>
            Here are references to the projects we have included:
            <ul>
              <li>
                <a
                  href={window.location.href + '/coguard_demo_dockerhub_httpd'}
                >
                  Apache HTTPD Docker image (version 2.4.57){' '}
                </a>
                <a
                  href="https://hub.docker.com/_/httpd"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InfoIcon fontSize="small" />
                </a>
              </li>
              <li>
                <a
                  href={window.location.href + '/coguard_demo_dockerhub_mysql'}
                >
                  MySQL Docker image (version 8.1.0){' '}
                </a>
                <a
                  href="https://hub.docker.com/_/mysql"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InfoIcon fontSize="small" />
                </a>
              </li>
              <li>
                <a
                  href={window.location.href + '/coguard_demo_dockerhub_nginx'}
                >
                  NGINX Docker image (version 1.25.2){' '}
                </a>
                <a
                  href="https://hub.docker.com/_/nginx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InfoIcon fontSize="small" />
                </a>
              </li>
              <li>
                <a href={window.location.href + '/coguard_demo_pantheon_mysql'}>
                  MySQL for Free Pantheon accounts{' '}
                </a>
                <a
                  href="https://pantheon.io/product/drupal-hosting"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InfoIcon fontSize="small" />
                </a>
              </li>
              <li>
                <a
                  href={
                    window.location.href + '/coguard_demo_dockerhub_postgres'
                  }
                >
                  Postgres Docker image (version 15.4){' '}
                </a>
                <a
                  href="https://hub.docker.com/_/postgres"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InfoIcon fontSize="small" />
                </a>
              </li>
            </ul>
          </p>
        </Alert>
      </Collapse>
      <HeaderBar
        clusterReportList={clusterReportList}
        clusterMap={clusterMap}
        totalReports={totalReports}
      />
      <ActiveIssues
        organizationName={organizationName}
        clusterMap={clusterMap}
      />
      <Section>
        <FailedChecksByCluster clusterMap={clusterMap} />
      </Section>
      <Card height="100vh" display="flex">
        {Object.keys(clusterMap).map((clusterName, i) => (
          <Box key={i}>
            <Section>
              <ClusterItem
                organizationName={organizationName}
                clusterName={clusterName}
                clusterItem={clusterMap[clusterName]}
              />
            </Section>
          </Box>
        ))}
      </Card>
    </Box>
  );
};

const CoguardOrgSummaryView = ({
  organizationName,
  open,
  setOpen,
  populateOpen,
  setPopulateOpen,
}) => {
  const organizationSummary = useOrganizationSummary(organizationName);
  const authProvider = useAuthProvider();
  return coguardCommonSummaryView(
    organizationName,
    organizationSummary,
    open,
    setOpen,
    populateOpen,
    setPopulateOpen,
    true,
    authProvider.token
  );
};

const CoguardUserSummaryView = ({
  organizationName,
  open,
  setOpen,
  populateOpen,
  setPopulateOpen,
}) => {
  const userSummary = useUserSummary(organizationName);
  const authProvider = useAuthProvider();
  return coguardCommonSummaryView(
    organizationName,
    userSummary,
    open,
    setOpen,
    populateOpen,
    setPopulateOpen,
    false,
    authProvider.token
  );
};

export const CoGuardOrgView = () => {
  const [open, setOpen] = React.useState(true);
  const [populateOpen, setPopulateOpen] = React.useState(true);
  const { organizationName } = useParams();
  const organizations = useOrganizations();
  if (isEmpty(organizations)) {
    return (
      <CoguardUserSummaryView
        organizationName={organizationName}
        open={open}
        setOpen={setOpen}
        populateOpen={populateOpen}
        setPopulateOpen={setPopulateOpen}
      />
    );
  }
  return (
    <CoguardOrgSummaryView
      organizationName={organizationName}
      open={open}
      setOpen={setOpen}
      populateOpen={populateOpen}
      setPopulateOpen={setPopulateOpen}
    />
  );
};
