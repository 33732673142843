import React, { useMemo } from 'react';
import { getLabelForSeverity } from '../util/SeverityLabelsUtil';
import { StackedTimeSeriesChart } from '../../charts/StackedTimeSeriesChart/StackedTimeSeriesChart';
import { getBadPalette } from '../../colors';
import { DateTime } from 'luxon';
import { Box } from '@mui/material';
import { chartHasData } from '../util/ChartsUtil';

const createSeries = (reports) => {
  const seriesTitles = [
    'Date',
    getLabelForSeverity(1),
    getLabelForSeverity(2),
    getLabelForSeverity(3),
    getLabelForSeverity(4),
    getLabelForSeverity(5),
  ];
  const seriesData = reports.map(([timestamp, report]) => {
    const datetime = DateTime.fromMillis(Number.parseInt(timestamp * 1000, 10));

    return [
      datetime.toFormat('MMM dd'),
      report.sev1,
      report.sev2,
      report.sev3,
      report.sev4,
      report.sev5,
    ];
  });
  return [seriesTitles, ...seriesData];
};

const getColorPalette = (paletteMap) => {
  return [
    paletteMap['bad1'],
    paletteMap['bad2'],
    paletteMap['bad3'],
    paletteMap['bad4'],
    paletteMap['bad5'],
  ];
};

export const HistoricalReportsForCluster = ({ reportsSummaries, title }) => {
  const data = useMemo(
    () => createSeries(reportsSummaries),
    [reportsSummaries]
  );
  const paletteMap = getBadPalette('badPalette1');
  const colorPalette = getColorPalette(paletteMap);
  const _chartHasData = useMemo(() => chartHasData(data), [data]);
  return (
    <>
      {title ? (
        <Box style={{ fontSize: '20px', textAlign: 'left' }}>{title}</Box>
      ) : null}
      {_chartHasData ? (
        <StackedTimeSeriesChart
          data={data}
          colorPalette={colorPalette}
        ></StackedTimeSeriesChart>
      ) : (
        <Box style={{ marginTop: '200px' }}>
          <div>No Issues Found.</div>
        </Box>
      )}
    </>
  );
};
