import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ClusterDisplay from '../clusterViewComponents/clusterDisplay';
import { TopBar } from './TopBar';
import { Box } from '@mui/material';
import config from '../../config/configuration';
import { CoGuardOrgView } from './CoGuardOrgView';
import { CoGuardClusterView } from './CoGuardClusterView';
import { CoGuardAdminView } from './CoGuardAdminView';
import { CoGuardConnectAccountsView } from './CoGuardConnectAccountsView';
import './CoGuardEnterprise.css';
import { CoGuardBreadcrumbs } from './CoGuardBreadcrumbs';
import { CoGuardOrgList } from './CoGuardOrgList';
import {
  getClustersRoute,
  getOrgsRoute,
  getAdminRoute,
  getConnectAccountsRoute,
} from '../routes';

export const CoGuardEnterprise = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const authProvider = props.authProvider;

  useEffect(() => {
    if (location.pathname === '/') {
      const { organization } = authProvider.tokenParsed;
      const { email } = authProvider.tokenParsed;
      if (isEmpty(organization)) {
        navigate(`/orgs/${email}`);
      } else if (organization.length === 1) {
        navigate(`/orgs/${organization}`);
      }
    }
  }, [
    authProvider.token,
    authProvider.tokenParsed,
    location.pathname,
    navigate,
  ]);

  const onLogout = () =>
    authProvider.logout({
      redirectUri: `${config.data.clientUrl}:${config.data.clientPort}`,
    });
  return (
    <Box>
      <TopBar onLogout={onLogout} />
      <Box className="coguard-enterprise-view">
        <Box sx={{ marginBottom: 2 }}>
          <Routes>
            <Route path={getOrgsRoute()} element={<></>} />
            <Route
              path={getOrgsRoute(':organizationName')}
              element={<CoGuardBreadcrumbs />}
            />
            <Route
              path={getOrgsRoute(':organizationName', ':clusterName')}
              element={<CoGuardBreadcrumbs />}
            />
          </Routes>
        </Box>
        <Routes>
          <Route path="/" element={<CoGuardOrgList />} />
          <Route path={getOrgsRoute()} element={<CoGuardOrgList />} />
          <Route
            path={getOrgsRoute(':organizationName')}
            element={<CoGuardOrgView />}
          />
          <Route
            path={getOrgsRoute(':organizationName', ':clusterName')}
            element={<CoGuardClusterView />}
          />
          <Route
            path={getClustersRoute()}
            element={<ClusterDisplay authProvider={authProvider} />}
          />
          <Route path={getAdminRoute()} element={<CoGuardAdminView />} />
          <Route
            path={getConnectAccountsRoute(':organizationName')}
            element={<CoGuardConnectAccountsView />}
          />
          <Route
            path={getConnectAccountsRoute()}
            element={<CoGuardConnectAccountsView />}
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default CoGuardEnterprise;
