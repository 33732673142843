const supportedServices = {
  kerberos: {
    mandatoryFiles: ['krb5.conf', 'kdc.conf'],
    displayName: 'Kerberos',
    extraMessage:
      'The use of the `includedir` or `include` directive is only supported in the enterprise version of CoGuard. Please ensure that your uploaded configuration files are not using it.',
  },
  mongodb: {
    mandatoryFiles: ['mongod.conf'],
    displayName: 'MongoDB',
    extraMessage: '',
  },
  nginx: {
    mandatoryFiles: ['nginx.conf'],
    displayName: 'NGINX',
    extraMessage:
      'The use of the `includedir` or `include` directive is only supported in the enterprise version of CoGuard. Please ensure that your uploaded configuration files are not using it.',
  },
  postgres: {
    mandatoryFiles: ['postgresql.conf', 'pg_hba.conf'],
    displayName: 'PostgreSQL',
    extraMessage:
      'The use of the `include_dir` or `include` directive is only supported in the enterprise version of CoGuard, as well as additional file inclusion via `@`. Please ensure that your uploaded configuration files are not using these directives.',
  },
  apache: {
    mandatoryFiles: ['httpd.conf'],
    displayName: 'Apache Webserver',
    extraMessage:
      'The use of the `Include` directive is only supported in the enterprise version of CoGuard. Please ensure that your uploaded configuration files are not using it.',
  },
  mysql: {
    mandatoryFiles: ['my.ini'],
    displayName: 'MySQL',
    extraMessage:
      'The use of the `!include` or `!includedir` directive is only supported in the enterprise version of CoGuard. Please ensure that your uploaded configuration files are not using it.',
  },
  elasticsearch: {
    mandatoryFiles: ['elasticsearch.yml'],
    displayName: 'ElasticSearch',
  },
  kubernetes: {
    mandatoryFiles: [
      'kube-apiserver.yaml',
      'kube-deployment.yaml',
      'kube-pod.yaml',
      'kube-pod-template.yaml',
      'kube-replication-controller.yaml',
      'kube-replica-set.yaml',
      'kube-stateful-set.yaml',
      'kube-controller-revision.yaml',
      'kube-daemon-set.yaml',
      'kube-job.yaml',
      'kube-cron-job.yaml',
      'kube-horizontal-pod-autoscaler.yaml',
      'kube-priority-class.yaml',
      'kube-controller-manager.yaml',
      'kube-scheduler.yaml',
      'etcd.yaml',
      'encryption-provider-config.yaml',
      'kubelet-configuration.json',
      'kubelet-configuration.yaml',
    ],
    displayName: 'Kubernetes',
    extraMessage:
      'We are supporting the configuration check of the Kube API server, the controller manager, the scheduler, the Kubelets and the etcd key value store. For Kubelets, depending on the distrubution, the format may be json or yaml. Please pick exactly one.',
  },
  chef: {
    mandatoryFiles: ['chef-server.rb'],
    displayName: 'Chef Server',
  },
  dockerfile: {
    mandatoryFiles: ['Dockerfile'],
    displayName: 'Docker',
  },
  cloudformation: {
    mandatoryFiles: ['aws_template.yaml', 'aws_template.json'],
    displayName: 'AWS CloudFormation',
    isClusterService: true,
  },
  azureresourcemanager: {
    mandatoryFiles: ['template.json'],
    displayName: 'Azure Resource Manager',
    isClusterService: true,
  },
  kafka: {
    mandatoryFiles: ['server.properties'],
    displayName: 'Apache Kafka',
  },
  hadoop: {
    mandatoryFiles: ['core-site.xml', 'hdfs-site.xml'],
    displayName: 'Apache Hadoop',
  },
  microsoft_iis: {
    mandatoryFiles: [
      'ApplicationHost.config',
      'Administration.config',
      'Redirection.config',
      'machine.config',
      'web.config',
    ],
    displayName: 'Microsoft IIS',
  },
  terraform: {
    mandatoryFiles: ['main.tf'],
    displayName: 'TerraForm',
    isClusterService: true,
  },
  tomcat: {
    mandatoryFiles: ['server.xml', 'web.xml', 'context.xml'],
    displayName: 'Tomcat',
  },
  opentelemetry_collector: {
    mandatoryFiles: ['config.yaml'],
    displayName: 'OpenTelemetry Collector',
  },
  redis: {
    mandatoryFiles: ['redis.conf', 'aclfile.conf'],
    displayName: 'Redis',
  },
  docker_compose: {
    mandatoryFiles: ['docker-compose.yml'],
    displayName: 'Docker Compose',
  },
  netlify: {
    mandatoryFiles: ['netlify.toml', '_headers'],
    displayName: 'Netlify',
    isClusterService: true,
  },
  ansible: {
    mandatoryFiles: [
      'task.yml',
      'playbook.yml',
      'handler.yml',
      'vars.yml',
      'defaults.yml',
      'meta.yml',
      'hostvars.yml',
      'groupvars.yml',
    ],
    displayName: 'Ansible',
    isClusterService: true,
  },
  openvpn: {
    mandatoryFiles: ['server.conf', 'client.conf'],
    displayName: 'OpenVPN',
  },
  rabbitmq: {
    mandatoryFiles: ['rabbitmq.conf'],
    displayName: 'RabbitMQ',
  },
  github_actions: {
    mandatoryFiles: ['action.yml'],
    displayName: 'GitHub Actions',
  },
  grafana: {
    mandatoryFiles: ['grafana.ini'],
    displayName: 'Grafana',
  },
  apache_spark: {
    mandatoryFiles: ['spark-defaults.conf'],
    displayName: 'Apache Spark',
  },
  apache_druid: {
    mandatoryFiles: ['runtime.properties'],
    displayName: 'Apache Druid',
  },
  systemd: {
    mandatoryFiles: [
      'service.service',
      'timer.timer',
      'socket.socket',
      'path.path',
      'device.device',
      'mount.mount',
      'swap.swap',
      'automount.automount',
      'target.target',
      'slice.slice',
      'scope.scope',
      'snapshot.snapshot',
      'systemd-system.conf',
    ],
    displayName: 'SystemD',
  },
  open_api: {
    mandatoryFiles: [
      'openapi.json',
      'openapi.yml',
    ],
    displayName: 'OpenAPI',
  },
  docker_daemon: {
    mandatoryFiles: [
      'daemon.json',
    ],
    displayName: 'Docker Daemon',
  },
  rethinkdb: {
    mandatoryFiles: [
      'default.conf',
    ],
    displayName: 'RethinkDB',
  },
};

export { supportedServices };
