import { Box, Button, Card, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { isEmpty } from 'lodash';
import { useOrganizations } from '../hooks/useOrganizations.hook';
import { useUserAccount } from '../hooks/useUserAccount.hook';
import { getSemanticPalette, getBadPalette } from '../colors';
import { useOrganizationSummary } from '../hooks/useOrganizationSummary.hook';
import { useUserSummary } from '../hooks/useUserSummary.hook';
import { Link, useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import { getLabelForSeverity } from './util/SeverityLabelsUtil';
import { getOrgsRoute } from '../routes';

const Section = ({ children }) => (
  <Box
    borderBottom={`1px solid ${getSemanticPalette('light').neutral}`}
    padding="16px"
  >
    {children}
  </Box>
);

const ActionButton = ({ children, to }) => {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" width="200px">
      <Button variant="contained" onClick={() => navigate(to)}>
        <Box display="flex" flexDirection="row" gap="4px" alignItems="center">
          {children}
        </Box>
      </Button>
    </Box>
  );
};

const commonSummary = (organizationName, summary) => {
  if (!summary) return <LinearProgress />;

  const { clusterCount, severityCounts, lastRan } = summary;
  const palette = getBadPalette('badPalette1');

  const url = getOrgsRoute(organizationName);

  return (
    <Box display="flex" flexDirection="row">
      {clusterCount > 0 ? (
        <Box flex="1" display="flex" flexDirection="column" lineHeight={2}>
          <Link to={url}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <ArrowForwardIosIcon fontSize="16" />
              <Box fontSize={20} fontWeight="bold" paddingX={1}>
                {organizationName}
              </Box>
            </Box>
          </Link>
          <Box>Clusters: {clusterCount}</Box>
          <Box
            display="flex"
            flexDirection={['column', 'column', 'column', 'row']}
            gap="4px"
          >
            <Box>Active Issues:</Box>
            <Box color={palette.bad5}>
              {severityCounts[4]} {getLabelForSeverity(5)},
            </Box>
            <Box color={palette.bad4}>
              {severityCounts[3]} {getLabelForSeverity(4)},
            </Box>
            <Box color={palette.bad3}>
              {severityCounts[2]} {getLabelForSeverity(3)},
            </Box>
            <Box color={palette.bad2}>
              {severityCounts[1]} {getLabelForSeverity(2)},
            </Box>
            <Box color={palette.bad1}>
              {severityCounts[0]} {getLabelForSeverity(1)}
            </Box>
          </Box>
          <Typography variant="subtitle1">Last Ran: {lastRan}</Typography>
        </Box>
      ) : (
        <Box flex="1" display="flex" flexDirection="column" lineHeight={2}>
          <Link to={url}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <ArrowForwardIosIcon fontSize="16" />
              <Box fontSize={20} fontWeight="bold" paddingX={1}>
                {organizationName}
              </Box>
            </Box>
          </Link>
          <Box>Clusters: {clusterCount}</Box>
          <Box
            display="flex"
            flexDirection={['column', 'column', 'column', 'row']}
            gap="4px"
          >
            <Box>Active Issues:</Box>
            <Box color={palette.bad1}>
              <a
                href="https://github.com/coguardio/coguard-cli"
                target="_blank"
                rel="noreferrer"
              >
                Install and Connect CoGuard CLI
              </a>
            </Box>
          </Box>
          <Typography variant="subtitle1">Last Ran: {lastRan}</Typography>
        </Box>
      )}

      <Box minWidth="50px" />

      <ActionButton to={url}>
        <PersonIcon />
        View
      </ActionButton>
    </Box>
  );
};

const OrgSummary = ({ organizationName }) => {
  const summary = useOrganizationSummary(organizationName);
  return commonSummary(organizationName, summary);
};

const UserSummary = ({ userName }) => {
  const summary = useUserSummary(userName);
  return commonSummary(userName, summary);
};

export const CoGuardOrgList = () => {
  const organizations = useOrganizations();
  const userAccount = useUserAccount();

  if (!isEmpty(organizations)) {
    return (
      <Card>
        <Section>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <h3>
              {organizations.length} Organization
              {organizations.length !== 1 ? 's' : null}
            </h3>
          </Box>
        </Section>

        {organizations.map((organizationName, i) => (
          <Box key={i}>
            <Section>
              <OrgSummary organizationName={organizationName} />
            </Section>
          </Box>
        ))}
      </Card>
    );
  }

  return (
    <Card>
      <Section>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <h3>
            {userAccount.length} User
            {userAccount.length !== 1 ? 's' : null}
          </h3>
        </Box>
      </Section>
      {userAccount.map((userAccountName, i) => (
        <Box key={i}>
          <Section>
            <UserSummary userName={userAccountName} />
          </Section>
        </Box>
      ))}
    </Card>
  );
};
