import { Box, Breadcrumbs } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getOrgsRoute } from '../routes';

export const CoGuardBreadcrumbs = () => {
  const { organizationName, clusterName } = useParams();

  return (
    <Breadcrumbs sx={{ fontSize: 14 }}>
      <Link to={getOrgsRoute()}>Organizations</Link>
      {clusterName ? (
        <Link to={getOrgsRoute(organizationName)}>{organizationName}</Link>
      ) : (
        <Box>{organizationName}</Box>
      )}
      {clusterName ? <Box>{clusterName}</Box> : null}
    </Breadcrumbs>
  );
};
