import React from 'react';
import { Box, AppBar, Button, Grid } from '@mui/material';
import { getLayoutPalette } from '../colors';
import logo from '../../assets/logo_new_with_colors_white.png';
import { Link, useResolvedPath } from 'react-router-dom';

export const TopBar = ({ onLogout }) => {
  const { pathname } = useResolvedPath();
  const palette = getLayoutPalette('default');
  const navItems = [{ label: 'Logout', clickFunc: onLogout }];
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" style={{ backgroundColor: palette.topBar }}>
        <Grid container justifyContent={'space-between'}>
          <Link
            style={{
              cursor: 'pointer',
              paddingLeft: '20px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
            to="/"
          >
            <img src={logo} alt="CoGuard Logo" width={'200px'} />
          </Link>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            padding="10px"
          >
            {navItems.map(({ label, route, clickFunc }) =>
              route ? (
                <Box key={label}>
                  <Link to={route} style={{ textDecoration: 'none' }}>
                    <Button
                      sx={{ color: pathname === route ? 'orange' : '#fff' }}
                    >
                      {label}
                    </Button>
                  </Link>
                </Box>
              ) : (
                <Button key={label} sx={{ color: '#fff' }} onClick={clickFunc}>
                  {label}
                </Button>
              )
            )}
          </Box>
        </Grid>
      </AppBar>
    </Box>
  );
};
