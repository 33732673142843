import { useEffect, useState, useCallback } from 'react';
import { isEmpty, get } from 'lodash';
import ApiConnection from '../../apiConnection/apiConnection';

/**
 *
 * @param {string} organizationName
 * @param {string} token
 * @param {string} clusterName
 * @param {string} reportName
 * @returns {Promise<*>}
 */
const getClusterReportJsonSummary = (
  organizationName,
  token,
  clusterName,
  reportName,
  isDefaultAccount
) => {
  if (!isDefaultAccount) {
    return ApiConnection.getClusterReportJsonSummary(
      organizationName,
      token,
      clusterName,
      reportName
    );
  }
  return ApiConnection.getCliClusterReportJsonSummary(
    organizationName,
    token,
    clusterName,
    reportName
  );
};

/**
 *
 * @param {string} organizationName
 * @param {string} token
 * @param {string} clusterName
 * @param {Array<string>} reportsList
 * @param {number} limit
 * @returns {Promise<*>}
 */
const fetchSummariesForCluster = async (
  organizationName,
  token,
  clusterName,
  reportsList,
  isDefaultAccount
) => {
  const sortedReportsList = reportsList.slice().reverse();
  sortedReportsList.sort();
  const reportsResults = await Promise.all(
    sortedReportsList.map((reportTimestamp) =>
      getClusterReportJsonSummary(
        organizationName,
        token,
        clusterName,
        reportTimestamp,
        isDefaultAccount
      )
    )
  );
  return sortedReportsList.map((reportTimestamp, i) => [
    reportTimestamp,
    reportsResults[i],
  ]);
};

/**
 *
 * @param {string} organizationName
 * @param {string} token
 * @param {object[]} reportsForCluster
 * @param {string} clusterName
 * @param {number} limit
 * @returns {{loading: boolean, error: unknown, clusters: unknown}}
 */
export const useReportSummariesForCluster = (
  organizationName,
  authProvider,
  reportsForCluster,
  clusterName
) => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState(null);
  const [error, setError] = useState(null);
  const token = authProvider.token;
  const isDefaultAccount = isEmpty(
    get(authProvider, ['tokenParsed', 'organization'], [])
  );

  useEffect(() => {
    if (!organizationName || !token) return;
    if (!reportsForCluster) return;
    if (!clusterName) return;
    fetchSummariesForCluster(
      organizationName,
      token,
      clusterName,
      reportsForCluster,
      isDefaultAccount
    )
      .then((reports) => setReports(reports))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [
    reportsForCluster,
    organizationName,
    token,
    clusterName,
    isDefaultAccount,
  ]);

  const reset = useCallback(() => {
    setReports(null);
  }, []);
  return {
    loading: loading,
    reports,
    error,
    reset,
  };
};
