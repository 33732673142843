import { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import ApiConnection from '../../apiConnection/apiConnection';
import { useAuthProvider } from '../../AuthProvider';

export const useClusterManifest = (organizationName, clusterName) => {
  const [clusterManifest, setClusterManifest] = useState();
  const authProvider = useAuthProvider();
  const token = authProvider.token;
  const isDefaultAccount = isEmpty(
    get(authProvider, ['tokenParsed', 'organization'], [])
  );

  useEffect(() => {
    if (organizationName && token && clusterName) {
      if (!isDefaultAccount) {
        ApiConnection.getClusterManifest(
          organizationName,
          token,
          clusterName
        ).then((result) => {
          setClusterManifest(result);
        });
      } else {
        ApiConnection.getCliClusterManifest(
          organizationName,
          token,
          clusterName
        ).then((result) => {
          setClusterManifest(result);
        });
      }
    }
  }, [organizationName, token, clusterName, isDefaultAccount]);

  return clusterManifest;
};
