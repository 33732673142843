import ApiConnection from '../../apiConnection/apiConnection';
import { useEffect, useState } from 'react';

export const fetchClusterList = async (organizationName, token) => {
  return await ApiConnection.getClusterListPromise(organizationName, token);
};

/**
 *
 * @param {string} organizationName
 * @param {string} token
 */
export const useClusterListHook = (organizationName, token) => {
  const [loading, setLoading] = useState(true);
  const [clusterList, setClusterList] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (organizationName && token) {
      fetchClusterList(organizationName, token)
        .then((list) => setClusterList(list))
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
    }
  }, [organizationName, token, setLoading, setClusterList]);

  return {
    loading,
    clusterList,
    error,
  };
};
