import { Box, Grid } from '@mui/material';
import React from 'react';

export const HeaderBox = ({ label, value, icon }) => {
  return (
    <Grid item>
      <Box
        style={{ fontSize: '30px', textAlign: 'center', paddingRight: '20px' }}
      >
        <Box style={{ fontSize: '20px' }}>{label}</Box>
        <Box>
          {value} {icon ? icon : <></>}
        </Box>
      </Box>
    </Grid>
  );
};
