import { Box } from '@mui/material';
import React from 'react';
import { get } from 'lodash';
import { supportedServices } from '../utils/supportedServicesMap';
import { DataTable } from './components/DataTable';

const COLUMNS = [
  { label: 'Machine', field: 'machineName' },
  { label: 'Service Name', field: 'serviceName' },
  { label: 'Service Type', field: 'serviceType' },
  { label: 'File Name', field: 'fileName' },
  { label: 'Directory Path', field: 'directoryPath' },
  { label: 'Default File Name', field: 'defaultFileName' },
  { label: 'File Type', field: 'fileType' },
  { label: 'Version', field: 'version' },
];

const EXPANDED_COLUMNS = [
  { label: 'File Name', field: 'fileName' },
  { label: 'Default File Name', field: 'directoryPath' },
  { label: 'Type', field: 'configFileType' },
];

export const FilePanel = ({ clusterManifest }) => {
  const machines = get(clusterManifest, 'machines', {});
  const clusterServices = get(clusterManifest, 'clusterServices', {});

  const flatMapFunc = (machineName, service, serviceName) => {
    if (!service.configFileList) return null;

    const expandedRows = (service.complimentaryFileList || []).map((file) => ({
      fileName: file.fileName,
      directoryPath: file.subPath,
      configFileType: file.configFileType,
    }));
    return service.configFileList.map((file) => ({
      machineName,
      serviceName,
      serviceType: supportedServices[service.serviceName].displayName,
      fileName: file.fileName,
      directoryPath: file.subPath,
      defaultFileName: file.defaultFileName,
      fileType: file.configFileType,
      version: service.version,
      expandedComponent: expandedRows.length ? (
        <DataTable columns={EXPANDED_COLUMNS} rows={expandedRows} />
      ) : null,
    }));
  };

  const rows = Object.keys(machines || {})
    .flatMap((machineName) => {
      const machine = machines[machineName];
      return Object.keys(machine.services || {}).flatMap((serviceName) =>
        flatMapFunc(machineName, machine.services[serviceName], serviceName)
      );
    })
    .concat(
      Object.keys(clusterServices || {}).flatMap((serviceName) =>
        flatMapFunc('', clusterServices[serviceName], serviceName)
      )
    )
    .filter((x) => x);

  return (
    <Box>
      <DataTable columns={COLUMNS} rows={rows} />
    </Box>
  );
};
