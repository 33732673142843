import React, { useState } from 'react';

import { indexOf, isEmpty, has, get } from 'lodash';
import ApiConnection from '../../apiConnection/apiConnection';
import { supportedServices } from '../utils/supportedServicesMap.js';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';

function AddServiceCard(props) {
  const [newServiceAddSelected, setNewServiceAddSelected] = useState(false);
  const [newServiceName, setNewServiceName] = useState('');
  const [newServiceKey, setNewServiceKey] = useState('');
  const [version, setVersion] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleServiceAdd = () => {
    const body = {
      serviceName: newServiceName,
      serviceKey: newServiceKey,
      version,
    };
    if (has(props, 'machineId')) {
      ApiConnection.addService(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        props.machineId,
        body
      )
        .then(() => {
          setNewServiceAddSelected(false);
          setErrorMessage('');
          props.resetClusterJsons(props.machineId);
        })
        .catch((err) => {
          setErrorMessage(`Failed to add the new service "${newServiceName}".`);
        });
    } else {
      ApiConnection.addClusterService(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        body
      )
        .then(() => {
          setNewServiceAddSelected(false);
          setErrorMessage('');
          props.resetClusterJsons('');
        })
        .catch((err) => {
          setErrorMessage(`Failed to add the new service "${newServiceName}".`);
        });
    }
  };

  const verifyAllInfoGiven = () => {
    return (
      !isEmpty(newServiceKey) &&
      !isEmpty(newServiceName) &&
      indexOf(props.serviceKeys, newServiceKey) === -1
    );
  };

  if (!newServiceAddSelected) {
    return (
      <Grid item>
        <Card style={{ width: 300, height: 300 }}>
          <CardHeader title="Add new service" />
          <CardContent
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Fab
              color="primary"
              aria-label="Add Service"
              onClick={() => setNewServiceAddSelected(true)}
            >
              <AddIcon />
            </Fab>
          </CardContent>
          <CardActions />
        </Card>
      </Grid>
    );
  }
  return (
    <Grid item>
      <Card style={{ width: 300, height: 300, overflowY: 'scroll' }}>
        <CardHeader title="Add new service" />
        <CardContent>
          <TextField
            label="Service Custom Identifier"
            aria-label="Service Custom Identifier"
            inputProps={{ 'data-testid': 'Service Custom Identifier' }}
            value={newServiceKey}
            onChange={(evt) => setNewServiceKey(evt.target.value)}
          />
          <FormControl>
            <InputLabel id="demo-simple-select-label">Service Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newServiceName}
              inputProps={{ 'data-testid': 'Service Default Name' }}
              onChange={(evt) => setNewServiceName(evt.target.value)}
              style={{ width: '25ch' }}
            >
              {Object.keys(supportedServices)
                .filter(
                  (serviceKey) =>
                    get(
                      supportedServices,
                      [serviceKey, 'isClusterService'],
                      false
                    ) !== has(props, 'machineId')
                )
                .map((serviceKey) => (
                  <MenuItem value={serviceKey} key={serviceKey}>
                    {supportedServices[serviceKey]['displayName']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            label="Version"
            value={version}
            onChange={(evt) => setVersion(evt.target.value)}
            inputProps={{ 'data-testid': 'Service Version' }}
          />
          <Button
            variant="contained"
            color="primary"
            aria-label="Submit Service"
            disabled={!verifyAllInfoGiven()}
            onClick={handleServiceAdd}
          >
            Add Service
          </Button>
          <Button
            variant="contained"
            color="secondary"
            aria-label="Cancel Adding New Service"
            onClick={() => setNewServiceAddSelected(false)}
          >
            Cancel
          </Button>
          {isEmpty(errorMessage) ? (
            ''
          ) : (
            <Alert severity="error">{errorMessage}</Alert>
          )}
        </CardContent>
        <CardActions />
      </Card>
    </Grid>
  );
}

export default AddServiceCard;
