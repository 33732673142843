import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { startsWith, get, isEmpty } from 'lodash';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { getSeverityByNumber } from '../util';

const URL_REGEX = new RegExp(
  '(^|[ \t\r\n:])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))', // eslint-disable-line no-control-regex
  'g'
);

const getURLSFromDescription = (fullDescription) => {
  const urls = fullDescription.match(URL_REGEX) || [];
  // There are some URLS that don't have a space between Source: and the url
  // The regex will match urls that start with : but this function is removing
  // the : at the beginning of the URLs
  return urls.map((url) => (url.startsWith(':') ? url.substring(1) : url));
};

const removeURLsAndSourcesFromDescription = (description, urls) => {
  const descriptionWithoutURls = urls.reduce(
    (desc, url) => desc.replace(url, ''),
    description
  );
  return descriptionWithoutURls.split('Source')[0];
};

const fullDescriptionToDescriptionStruct = (fullDescription) => {
  if (typeof fullDescription === 'string') {
    const parts = fullDescription.split('Remediation:');
    const sourceParts = fullDescription.split('Source: ');
    const urls = getURLSFromDescription(fullDescription);
    const hasURLS = urls.length > 0;
    if (parts.length === 2) {
      if (hasURLS) {
        return {
          problem: parts[0],
          solution: removeURLsAndSourcesFromDescription(parts[1], urls),
          sources: urls,
        };
      } else {
        return {
          problem: parts[0],
          solution: parts[1],
        };
      }
    } else {
      if (hasURLS) {
        return {
          plainDescription: removeURLsAndSourcesFromDescription(
            sourceParts[0],
            urls
          ),
          sources: urls,
        };
      } else {
        return {
          plainDescription: fullDescription,
        };
      }
    }
  } else {
    return {
      problem: fullDescription['documentation'],
      solution: fullDescription['remediation'],
      sources: fullDescription['sources'],
      scenarios: fullDescription['scenarios'],
    };
  }
};

const compareChecksBySeverity = (checkA, checkB) => {
  return checkB.rule.severity - checkA.rule.severity;
};

function createData(
  severityLabel,
  machine,
  service,
  identifier,
  fullDescription,
  color,
  fontColor,
  fromLine,
  toLine,
  configFile,
) {
  return {
    severityLabel,
    machine,
    service,
    identifier,
    fullDescription,
    color,
    fontColor,
    fromLine,
    toLine,
    configFile,
  };
}

const getRowForFailedCheck = (failedCheck) => {
  const rule = failedCheck.rule;
  const severityObj = getSeverityByNumber(rule.severity);
  return createData(
    severityObj.label,
    failedCheck.machine,
    failedCheck.service,
    rule.name,
    rule.documentation,
    severityObj.color,
    severityObj.fontColor,
    get(failedCheck, 'fromLine', 0),
    get(failedCheck, 'toLine', 1),
    get(failedCheck, 'config_file', {}),
  );
};

const ClusterReportRow = ({
  severityLabel,
  machine,
  service,
  identifier,
  fullDescription,
  color,
  fontColor,
  index,
  fromLine,
  toLine,
  configFile,
}) => {
  const [open, setOpen] = useState(false);
  const fullDescriptionStruct =
    fullDescriptionToDescriptionStruct(fullDescription);
  return (
    <>
      <TableRow
        key={`${machine}${index}`}
        style={{
          borderTopStyle: open ? 'solid' : 'none',
          borderLeftStyle: open ? 'solid' : 'none',
          borderRightStyle: open ? 'solid' : 'none',
          borderColor: open ? color : undefined,
          backgroundColor: open ? color : undefined,
          fontSize: open ? '18px' : undefined,
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{
            borderLeftStyle: open ? 'none' : 'solid',
            borderLeftColor: open ? undefined : color,
            fontWeight: open ? 'bold' : undefined,
            color: open ? fontColor : undefined,
          }}
        >
          {severityLabel}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            fontWeight: open ? 'bold' : undefined,
            color: open ? fontColor : undefined,
          }}
        >
          {machine}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            fontWeight: open ? 'bold' : undefined,
            color: open ? fontColor : undefined,
          }}
        >
          {service}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            fontWeight: open ? 'bold' : undefined,
            color: open ? fontColor : undefined,
          }}
        >
          {identifier}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            fontWeight: open ? 'bold' : undefined,
          }}
        >
          <Button
            variant="text"
            onClick={() => setOpen(!open)}
            style={{ color: open ? fontColor : undefined }}
          >
            {open ? 'Close' : 'Learn More'}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow
        style={{
          borderBottomStyle: open ? 'solid' : 'none',
          borderLeftStyle: open ? 'solid' : 'none',
          borderRightStyle: open ? 'solid' : 'none',
          borderColor: open ? color : undefined,
          fontSize: open ? '18px' : undefined,
        }}
      >
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {fullDescriptionStruct.plainDescription ? (
                <>
                  <Box>
                    <h3>Description</h3>
                  </Box>
                  <Box>{fullDescriptionStruct.plainDescription}</Box>
                </>
              ) : (
                <>
                  <Box>
                    <h3>What?</h3>
                  </Box>
                  <Box>{fullDescriptionStruct.problem}</Box>
                  {fullDescriptionStruct.solution && (
                    <Box>
                      <h3>How to fix it?</h3>
                    </Box>
                  )}
                  <Box>{fullDescriptionStruct.solution}</Box>
                </>
              )}
              {fullDescriptionStruct.sources ? (
                <Box>
                  <h3>More Info</h3>
                  {fullDescriptionStruct.sources.map((source, i) => (
                    <Box
                      key={i}
                      style={{ marginTop: '15px', marginBottom: '10px' }}
                    >
                      <a target={'_blank'} href={source} rel="noreferrer">
                        {source}
                      </a>
                    </Box>
                  ))}
                </Box>
              ) : (
                <></>
              )}
              {fullDescriptionStruct.scenarios ? (
                <Box>
                  <h3>References for the specific ruleset used</h3>
                  {fullDescriptionStruct.scenarios.map((scenario, i) => (
                    <Box
                      key={i}
                      style={{ marginTop: '15px', marginBottom: '10px' }}
                    >
                      {scenario}
                    </Box>
                  ))}
                </Box>
              ) : (
                <></>
              )}
              {!isEmpty(configFile) ? (
                <Box>
                  <Box>
                    <SimpleTreeView disableSelection>
                      <TreeItem itemId="ConfigFile" label={`Configuration file: ${get(configFile, 'fileName', '')} (expand for details)`}>
                        <TreeItem
                          itemId="Repository or path"
                          label={'Layer: ' + (startsWith(service, 'included_docker_image') ? 'Included Docker image' : 'Repository folder path')}
                        />
                        <TreeItem itemId="File Path" label={`Path: ${get(configFile, 'subPath', '.')}/${get(configFile, 'fileName', '')}`} />
                        {(
                          fromLine !== 0 || toLine !== 1) && (fromLine != null) && (toLine != null)
                         ? (<TreeItem itemId="Line Range" label={`Line-range: ${fromLine + 1} - ${toLine + 1}`} />)
                         : (<TreeItem disabled itemId="Line Range" label="Line Range: N/A" />
                           )}
                      </TreeItem>
                    </SimpleTreeView>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ClusterReportRows = ({ failedChecks }) => {
  const data = failedChecks.map(getRowForFailedCheck);
  return data.map((props, index) => {
    return (
      <ClusterReportRow {...props} key={index} index={index}></ClusterReportRow>
    );
  });
};

export const ClusterReportTable = ({ report }) => {
  if (!report) return <></>;
  if (!report['failed']) return <></>;
  const failedChecks = report['failed'];
  failedChecks.sort(compareChecksBySeverity);
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Severity</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="left">
              Machine
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="left">
              Service
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="left">
              Identifier
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ClusterReportRows failedChecks={failedChecks}></ClusterReportRows>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
