import React from 'react';
import { Chart } from 'react-google-charts';

/**
 *
 * Expects data in the following format
 *
 * [
 *   [HeaderA, Name of first value, Name of second value, ...] <- Series structure
 *   [Series Name, value, value, ...] <- Series
 *   [Series Name, value, value, ...] <- Series
 *   ...
 * ]
 *
 * @param {Array<Array<*>>} data
 * @param {Array<string>?} colorPalette
 * @param {string} xAxisLabel
 * @param {string} yAxisLabel?
 * @returns {JSX.Element}
 * @constructor
 */
export const StackedBarChart = ({
  data,
  colorPalette,
  xAxisLabel,
  yAxisLabel,
}) => {
  const options = {
    chartArea: { width: '50%' },
    colors: colorPalette,
    isStacked: true,
    focusTarget: 'category',
    legend: {
      textStyle: {
        fontName: 'Roboto',
      },
    },
    hAxis: {
      title: xAxisLabel,
      minValue: 0,
      titleTextStyle: {
        fontName: 'Roboto',
      },
    },
    vAxis: {
      title: yAxisLabel,
      titleTextStyle: {
        fontName: 'Roboto',
      },
      textStyle: {
        fontName: 'Roboto',
      },
    },
  };

  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};
