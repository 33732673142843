import { getBadPalette } from '../../colors';

const CriticalItems = [
  'Data potentially available externally',
  'Mechanisms for data/disaster recovery/post-incident analysis disabled',
  'When access to the hosting machine, data can be fully taken out',
  'If exploitable, data is open or damage can be made',
];

const HighItems = [
  'Data potentially externally available, but high effort needed to access it',
  'When access to the hosting machine, sensitive information can partially being taken out',
  'Usually Level 5, but only available on Enterprise edition',
  'Available, but too loose access restrictions',
  'Default ports are used',
];

const ModerateItems = [
  'Settings that are set, but are not taking effect because others are overwriting it. I.e. source of unintentional behavior',
  'Potentially highly performance affecting',
  '“High Availability” violation',
];

const ModerateLowItems = [
  'Performance affecting (slightly)',
  'Potential for filling up logging and being too loud',
  'The “are you sure” category',
  'Potential for too little logging.',
  'Potential for loss of logging control',
];

const LowItems = ['Linting', '“Nice to have” for extra system logs'];

export const SeverityLevelsDescriptions = {
  Critical: {
    label: 'Critical',
    severity: 5,
    description: CriticalItems,
    color: getBadPalette('badPalette1')['bad5'],
    fontColor: '#FFF',
  },
  High: {
    label: 'High',
    severity: 4,
    description: HighItems,
    color: getBadPalette('badPalette1')['bad4'],
    fontColor: '#FFF',
  },
  Moderate: {
    label: 'Moderate',
    severity: 3,
    description: ModerateItems,
    color: getBadPalette('badPalette1')['bad3'],
    fontColor: '#000',
  },
  ModerateLow: {
    label: 'Moderate-Low',
    severity: 2,
    description: ModerateLowItems,
    color: getBadPalette('badPalette1')['bad2'],
    fontColor: '#FFF',
  },
  Low: {
    label: 'Low',
    severity: 1,
    description: LowItems,
    color: getBadPalette('badPalette1')['bad1'],
    fontColor: '#FFF',
  },
};

export const getSeverityByNumber = (severityAsNumber) => {
  const key = Object.keys(SeverityLevelsDescriptions).find((key) => {
    const sev = SeverityLevelsDescriptions[key];
    return sev.severity === severityAsNumber;
  });
  return SeverityLevelsDescriptions[key];
};
