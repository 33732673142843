import { isEmpty, get, has } from 'lodash';
import { useAuthProvider } from '../../AuthProvider';

export const useOrganizations = () => {
  const authProvider = useAuthProvider();
  if (!authProvider.tokenParsed) return [];

  const { organization } = authProvider.tokenParsed;

  if (organization == null) {
    return [];
  }

  if (typeof organization === 'string') return [organization];

  if (Array.isArray(organization)) {
    if (isEmpty(organization)) {
      if (
        has(
          get(authProvider, ['tokenParsed', 'realm_access', 'roles'], []),
          'ENTERPRISE'
        )
      ) {
        throw new Error(
          'Any paid subscription needs to have an assigned organization.'
        );
      }
      return organization;
    } else {
      return organization;
    }
  }

  throw new Error(`unknown organization format ${typeof organization}`);
};
