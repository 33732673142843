import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { filter, indexOf, concat, findIndex, get, has, isEmpty } from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';

import ApiConnection from '../../apiConnection/apiConnection';

import supportedConfigFileTypes from '../utils/supportedConfigFileTypes.js';

function AddComplimentaryFileRow(props) {
  const [fileName, setFileName] = useState('');
  const [subPath, setSubPath] = useState('.');
  const [configFileType, setConfigFileType] = useState('custom');
  const [complimentaryFile, setComplimentaryFile] = useState(null);
  const [aliases, setAliases] = useState([]);

  /**
   * A testing function to figure out if a new complimentary file can be added or not.
   * It checks that the important fields are there, and if the file is not representing
   * something already existing.
   */
  const newComplimentaryFileCanBeAdded = () => {
    return (
      !isEmpty(fileName) &&
      !isEmpty(subPath) &&
      !(complimentaryFile == null) &&
      findIndex(
        concat(props.currentConfigFiles, props.currentComplimentaryFiles),
        (conf) =>
          get(conf, 'fileName') === fileName &&
          get(conf, 'subPath').replace(/\/+$/, '') ===
            subPath.replace(/\/+$/, '')
      ) === -1
    );
  };

  /**
   * The call to the endpoints once the button is being pressed to add
   * the file.
   */
  const handleCreateNewComplimentaryFileEntry = () => {
    if (has(props, 'machineId')) {
      ApiConnection.upsertComplimentaryFileEntry(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        props.machineId,
        props.serviceId,
        fileName,
        subPath,
        aliases,
        [],
        configFileType
      )
        .then((upsertResult) => {
          if (upsertResult !== 'false') {
            ApiConnection.uploadComplimentaryFile(
              props.organizationName,
              props.authProvider.token,
              props.clusterName,
              props.machineId,
              props.serviceId,
              fileName,
              subPath,
              complimentaryFile,
              configFileType
            )
              .then(() => {
                setFileName('');
                setSubPath('.');
                setConfigFileType('custom');
                setAliases([]);
                props.setErrorMessage('');
                props.resetClusterJsons(props.machineId);
              })
              .catch((err) => {
                props.setErrorMessage(
                  `Failed to upload the complimentary file named ${fileName}.`
                );
              });
          }
        })
        .catch((err) => {
          props.setErrorMessage(
            `Failed to upload the complimentary file named ${fileName}.`
          );
        });
    } else {
      ApiConnection.upsertComplimentaryFileEntryClusterService(
        props.organizationName,
        props.authProvider.token,
        props.clusterName,
        props.serviceId,
        fileName,
        subPath,
        aliases,
        [],
        configFileType
      )
        .then((upsertResult) => {
          if (upsertResult !== 'false') {
            ApiConnection.uploadComplimentaryFileClusterService(
              props.organizationName,
              props.authProvider.token,
              props.clusterName,
              props.serviceId,
              fileName,
              subPath,
              complimentaryFile,
              configFileType
            )
              .then(() => {
                setFileName('');
                setSubPath('.');
                setConfigFileType('custom');
                setAliases([]);
                props.setErrorMessage('');
                props.resetClusterJsons('');
              })
              .catch((err) => {
                props.setErrorMessage(
                  `Failed to upload the complimentary file named ${fileName}.`
                );
              });
          }
        })
        .catch((err) => {
          props.setErrorMessage(
            `Failed to upload the complimentary file entry named ${fileName}.`
          );
        });
    }
  };

  /**
   * The predicate to check if the alias was already assigned to some other
   * complimentary file or configuration file.
   */
  const checkIfAliasesAlreadyAssigned = (value) => {
    const filesWithExistingAliases = filter(
      concat(props.currentConfigFiles, props.currentComplimentaryFiles),
      (configFile) => indexOf(get(configFile, 'aliasList', []), value) !== -1
    );
    return !isEmpty(filesWithExistingAliases);
  };

  return (
    <TableRow>
      <TableCell>
        <TextField
          value={fileName}
          onChange={(evt) => setFileName(evt.target.value)}
          style={{ width: '20ch' }}
          inputProps={{ 'data-testid': 'File Name Config File' }}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={aliases}
          onChange={(evt, value, reason) => {
            if (reason === 'createOption') {
              if (!checkIfAliasesAlreadyAssigned(evt.target.value)) {
                setAliases(value);
              } else {
                props.setErrorMessage(
                  `Alias '${evt.target.value}' already in use.`
                );
              }
            } else {
              setAliases(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              placeholder="Add Aliases Add Complimentary"
            />
          )}
        />
      </TableCell>
      <TableCell>
        <TextField
          value={subPath}
          onChange={(evt) => setSubPath(evt.target.value)}
          style={{ width: '10ch' }}
          inputProps={{ 'data-testid': 'SubPath Complimentary File' }}
        />
      </TableCell>
      <TableCell>
        <FormControl>
          <Select
            value={configFileType}
            onChange={(evt) => setConfigFileType(evt.target.value)}
            style={{ width: '25ch' }}
            inputProps={{ 'data-testid': 'Config File Type' }}
          >
            {supportedConfigFileTypes.map((configFile) => (
              <MenuItem key={configFile} value={configFile}>
                {configFile}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <input
          type="file"
          name="ConfigFile"
          data-testid="Config File Upload Field"
          onChange={(evt) => setComplimentaryFile(evt.target.files[0])}
        />
      </TableCell>
      <TableCell>
        <IconButton
          color="primary"
          disabled={!newComplimentaryFileCanBeAdded()}
          aria-label="Add New Complimentary File Button"
          onClick={handleCreateNewComplimentaryFileEntry}
        >
          <AddBoxIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default AddComplimentaryFileRow;
