import { useEffect, useState } from 'react';
import { useAuthProvider } from '../../AuthProvider';
import { isEmpty, get } from 'lodash';
import { fetchReportsListForCluster } from './useClustersReportsList.hook';

export const useClusterReportsList = (organizationName, clusterName) => {
  const authProvider = useAuthProvider();
  const token = authProvider.token;
  const isDefaultAccount = isEmpty(
    get(authProvider, ['tokenParsed', 'organization'], [])
  );
  const [clusterReportList, setClusterReportList] = useState();

  useEffect(() => {
    if (token && organizationName && clusterName) {
      fetchReportsListForCluster(
        organizationName,
        token,
        clusterName,
        isDefaultAccount
      ).then((reports) => {
        setClusterReportList(reports);
      });
    }
  }, [
    token,
    organizationName,
    clusterName,
    setClusterReportList,
    isDefaultAccount,
  ]);

  return clusterReportList;
};
