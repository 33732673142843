import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getSemanticPalette } from '../../colors';

const Row = ({ hasExpanded, row, columns }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {hasExpanded ? (
          row.expandedComponent ? (
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          ) : (
            <TableCell></TableCell>
          )
        ) : null}
        {columns.map((column, i) => (
          <TableCell key={i}>{row[column.field]}</TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                border: `1px solid ${getSemanticPalette('light').neutral}`,
              }}
            >
              {row.expandedComponent}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const DataTable = ({ columns, rows }) => {
  const hasExpanded = rows.some((row) => row.expandedComponent);
  return (
    <Table>
      <TableHead>
        <TableRow>
          {hasExpanded ? <TableCell></TableCell> : null}
          {columns.map((column, i) => (
            <TableCell key={i}>
              <strong>{column.label}</strong>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => (
          <Row key={i} row={row} columns={columns} hasExpanded={hasExpanded} />
        ))}
      </TableBody>
    </Table>
  );
};
