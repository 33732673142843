export const getLabelForSeverity = (severity) => {
  switch (severity) {
    case 5:
      return 'Critical';
    case 4:
      return 'High';
    case 3:
      return 'Moderate';
    case 2:
      return 'Moderate-Low';
    case 1:
      return 'Low';
    default:
      return severity;
  }
};
