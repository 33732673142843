import React from 'react';
import Keycloak from 'keycloak-js';
import config from '../config/configuration';

/**
 * This is the display for the login of the user.
 */
export class LoginPage extends React.Component {
  componentDidMount() {
    const keycloak = new Keycloak(config.data.keycloakConf);
    keycloak.init({
      onLoad: 'login-required',
    });
  }

  /**
   * The main render function.
   */
  render() {
    return <div className="loginForm">Redirecting to login...</div>;
  }
}
