import { get } from 'lodash';
import { DateTime } from 'luxon';

const MAXIMUM_RECOMENDED_DAYS_BETWEEN_SCANS = 7;

/**
 * Returns the total number of failed checks by severity
 * [severity1, severity2, severity3, severity4, severity5]
 * @param {Array<*>} failed
 * @returns {number[]}
 */
export const sumIssuesBySeverityLevel = (report) => {
  const failed = get(report, 'failed', []);
  const totals = [0, 0, 0, 0, 0];
  if (failed && failed.length) {
    failed.forEach((check) => {
      const { severity } = check.rule;
      const arrIndex = severity - 1;
      totals[arrIndex] = totals[arrIndex] + 1;
    });
  }

  return totals;
};

export const isClusterHealthy = (failedChecks) => {
  const issuesBySeverity = sumIssuesBySeverityLevel(failedChecks['report']);
  const sev5 = issuesBySeverity[4];
  const sev4 = issuesBySeverity[3];
  return sev5 + sev4 === 0;
};

export const getLastRanInDays = (clusterReportList) => {
  const allReports = Object.values(clusterReportList)
    .flat()
    .filter((x) => x);
  if (allReports == null || allReports.length === 0) return -1;
  allReports.sort();
  const lastRan = allReports[allReports.length - 1];
  const today = DateTime.now();
  const datetime = DateTime.fromMillis(Number.parseInt(lastRan * 1000, 10));
  const diff = today.diff(datetime, ['days']);
  const daysDiff = diff.values.days;
  if (daysDiff) {
    return Math.floor(daysDiff);
  }
  return 0;
};

export const getLastRanAsString = (clusterReportList) => {
  const daysDiff = getLastRanInDays(clusterReportList);
  if (daysDiff) {
    if (daysDiff === -1) return 'Never';
    return `${daysDiff.toFixed()} days ago`;
  }
  return 'Today';
};

export const lastScanRanBeyondRecommendedTheshold = (clusterReportList) => {
  const daysDiff = getLastRanInDays(clusterReportList);
  return daysDiff >= MAXIMUM_RECOMENDED_DAYS_BETWEEN_SCANS;
};
