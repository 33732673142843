import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { isEmpty } from 'lodash';
import AddCidrRow from './AddCidrRow';
import Alert from '@mui/material/Alert';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import IconButton from '@mui/material/IconButton';
import ExposureIcon from '@mui/icons-material/Exposure';
import ApiConnection from '../../apiConnection/apiConnection';

function CidrInfo(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const handleClose = () => {
    props.onClose();
  };

  const handleRemoveCidr = (
    priority,
    protocol,
    cidrBlock,
    beginPort,
    endPort
  ) => {
    ApiConnection.deleteCidrRule(
      props.organizationName,
      props.authProvider.token,
      props.clusterName,
      props.machineId,
      props.direction,
      priority,
      protocol,
      cidrBlock,
      beginPort,
      endPort
    )
      .then(() => {
        setErrorMessage('');
        props.resetClusterJsons('');
      })
      .catch((err) => {
        setErrorMessage('Failed to delete the cidr rule.');
      });
  };

  return (
    <Dialog onClose={handleClose} open={props.open} maxWidth="md">
      <DialogTitle>{props.direction}</DialogTitle>
      {isEmpty(errorMessage) ? (
        ''
      ) : (
        <Alert severity="error">{errorMessage}</Alert>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ border: '1px solid #ddd' }}>
                Priority
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd' }}>
                Protocol
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd' }}>
                CIDR Block
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd' }}>
                Begin Port
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd' }}>
                End Port
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd' }} align="center">
                <ExposureIcon />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rules.map((row) => {
              return (
                <TableRow
                  key={`${row.priority}${row.protocol}${row.cidrBlock}${row.beginPort}${row.endPort}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    {row.priority}
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }} align="right">
                    {row.protocol}
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }} align="right">
                    {row.cidrBlock}
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }} align="right">
                    {row.beginPort}
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }} align="right">
                    {row.endPort}
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }} align="right">
                    <IconButton
                      color="secondary"
                      aria-label="Remove CIDR entry"
                      onClick={() =>
                        handleRemoveCidr(
                          row.priority,
                          row.protocol,
                          row.cidrBlock,
                          row.beginPort,
                          row.endPort
                        )
                      }
                    >
                      <IndeterminateCheckBoxIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            <AddCidrRow
              organizationName={props.organizationName}
              clusterName={props.clusterName}
              machineId={props.machineId}
              resetClusterJsons={props.resetClusterJsons}
              setErrorMessage={setErrorMessage}
              direction={props.direction}
              authProvider={props.authProvider}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
}

export default CidrInfo;
