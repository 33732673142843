export const RedColorPalette = {
  bad1: '#f66364',
  bad2: '#f33334',
  bad3: '#dc0d0e',
  bad4: '#b90c0d',
  bad5: '#930a0a',
};

const BadPalette2 = {
  bad1: '#f7b267',
  bad2: '#f79d65',
  bad3: '#f4845f',
  bad4: '#f27059',
  bad5: '#f25c54',
};

const BadPalette3 = {
  bad1: '#ffcdb2',
  bad2: '#ffb4a2',
  bad3: '#e5989b',
  bad4: '#b5838d',
  bad5: '#6d6875',
};

const SupermanPalette = {
  bad5: '#bb070e',
  bad4: '#f11712',
  bad3: '#FFCC00',
  bad2: '#240c60',
  bad1: '#2a318b',
};

/**
 *
 * @param {string} paletteName
 */
export const getBadPalette = (paletteName) => {
  switch (paletteName) {
    case 'badPalette1':
      return SupermanPalette;
    case 'badPalette2':
      return BadPalette2;
    case 'badPalette3':
      return BadPalette3;
    default:
      throw new Error(`Unknown Palette: ${paletteName}`);
  }
};

const DarkSemanticPalette = {
  negative: '#BB0000',
  critical: '#E78C07',
  positive: '#2B7D2B',
  neutral: '#5E696E',
  information: '#427cac',
};

const LightSemanticPalette = {
  negative: '#FF8888',
  critical: '#FABD64',
  positive: '#ABE2AB',
  neutral: '#D3D7D9',
  information: '#91c8f6',
};

export const getSemanticPalette = (paletteName) => {
  switch (paletteName) {
    case 'light':
      return LightSemanticPalette;
    case 'dark':
      return DarkSemanticPalette;
    default:
      throw new Error(`Unknown Palette: ${paletteName}`);
  }
};

const LayoutPalette = {
  divider: '#e0e0e0',
  topBar: '#191e3d',
};

export const getLayoutPalette = (paletteName) => {
  switch (paletteName) {
    case 'default':
      return LayoutPalette;
    default:
      throw new Error(`Unknown Layout Palette ${paletteName}`);
  }
};
