import { useEffect, useState } from 'react';
import ApiConnection from '../../apiConnection/apiConnection';
import { useClusterListHook } from './useClusterList.hook';

/**
 *
 * @param {string} organizationName
 * @param {string} token
 * @param {string} clusterName
 * @returns {Promise<*>}
 */
export const fetchReportsListForCluster = async (
  organizationName,
  token,
  clusterName,
  isDefaultAccount = false
) => {
  if (!isDefaultAccount) {
    return await ApiConnection.getClusterReportList(
      organizationName,
      token,
      clusterName
    );
  }
  return await ApiConnection.getCliClusterReportList(
    organizationName,
    token,
    clusterName
  );
};

/**
 *
 * @param {string} organizationName
 * @param {string} token
 * @param {Array<string>} clusterList
 */
const fetchReportsListsForAllClusters = async (
  organizationName,
  token,
  clusterList
) => {
  const reports = await Promise.all(
    clusterList.map((clusterName) =>
      fetchReportsListForCluster(organizationName, token, clusterName).catch(
        () => []
      )
    )
  );

  return clusterList.reduce((acc, clusterName, i) => {
    acc[clusterName] = reports[i];
    return acc;
  }, {});
};

/**
 *
 * @param {string} organizationName
 * @param {string} token
 * @returns {{loading: boolean, error: unknown, clusters: unknown}}
 */
export const useClustersReportsList = (organizationName, token) => {
  const {
    clusterList,
    loading: clusterListLoading,
    error: clusterListError,
  } = useClusterListHook(organizationName, token);
  const [loading, setLoading] = useState(true);
  const [clusterReportList, setClusterReportList] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!organizationName || !token) return;
    if (!clusterList) return;
    fetchReportsListsForAllClusters(organizationName, token, clusterList)
      .then((clusterReportList) => setClusterReportList(clusterReportList))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [clusterList, organizationName, token]);

  return {
    loading: clusterListLoading || loading,
    clusterReportList,
    error: error || clusterListError,
  };
};
