import React, { useMemo } from 'react';
import { Chart } from 'react-google-charts';

const replaceZerosWithNulls = (number) => {
  return number === 0 ? null : number;
};

/**
 Google charts likes to display values even when they're 0
 Google charts requires null values instead of 0 to completely
 hide the values from the charts.
 However, if the series starts with null like [null, 2, 3, 4]
 Google charts can't infer the type so we need to declare the type
 explicitly on the header
 */
const transformDataForGoogleCharts = (data) => {
  const header = data[0];
  const newHeader = header.map((x, i) =>
    i === 0 ? x : { label: x, type: 'number' }
  );
  const rows = data.slice(1);
  const newRows = rows.map((row) => row.map(replaceZerosWithNulls));
  return [newHeader, ...newRows];
};

/**
 *
 * Expects data in the following format
 *
 * [
 *   [HeaderA, Name of first value, Name of second value, ...] <- Series structure
 *   [Column Name, value, value, ...] <- Series
 *   [Column Name, value, value, ...] <- Series
 *   ...
 * ]
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const StackedTimeSeriesChart = ({ data, colorPalette }) => {
  const _data = useMemo(() => transformDataForGoogleCharts(data), [data]);
  const options = {
    chartArea: { width: '70%' },
    colors: colorPalette,
    isStacked: true,
    legend: {
      textStyle: {
        fontName: 'Roboto',
      },
    },
    hAxis: {
      // title: xAxisLabel,
      minValue: 0,
      titleTextStyle: {
        fontName: 'Roboto',
      },
    },
    focusTarget: 'category',
    vAxis: {
      // title: yAxisLabel,
      titleTextStyle: {
        fontName: 'Roboto',
      },
      textStyle: {
        fontName: 'Roboto',
      },
    },
  };

  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="300px"
      data={_data}
      options={options}
    />
  );
};
